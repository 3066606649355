import { produce } from 'immer';
import { set as lSet } from 'lodash';
import { create } from 'zustand';

import type { Order } from '../../types';
import { COST_APPROVAL_FREE_DEFAULT } from '../../utils/constants';

interface IOrderState {
  nonce?: string;
  order?: Order;
  resetOrderState: () => void;
  setNonce: (nonce: string) => void;
  setValue: (value: string | boolean | number, key: string) => void;
  setAccessories: (accessories: Order['accessories']) => void;
  setJaws: (jaws: Order['jaws']) => void;
  setService: (service: string) => void;
  setProduct: (id: string | number, name: string) => void;
}

const today = new Date();

today.setDate(today.getDate() + 1);

const initialState = {
  order: {
    // extra setter
    service: '',
    product: '',
    product_name: '',
    description: '',
    bridgingDeviceRequired: false,
    checkedLegal: false,
    jaws: {
      jaws_12_35_mm: 0,
      jaws_42_54_mm: 0,
      jaws_64_mm: 0
    },
    accessories: {
      charger: 0,
      case: 0,
      mains_adapter: 0,
      batteries: 0
    },
    // generic setter
    customer_number: '',
    reference_number: '',
    order_info: '',
    pickup_date: today.toLocaleDateString('de-DE'),
    number_of_parcels: 1,
    cost_approval: COST_APPROVAL_FREE_DEFAULT,
    // address setter
    deliveryAddress: undefined,
    diffDeliveryAddress: false,
    pickupAddress: undefined,
    diffPickupAddress: false,
    address: undefined
  }
};

const useOrderStore = create<IOrderState>(set => ({
  ...initialState,
  resetOrderState: () => {
    set(initialState);
  },
  // setters
  setNonce: nonce =>
    set(
      produce(draft => {
        draft.nonce = nonce;
      })
    ),
  setValue: (value, key) =>
    set(
      produce(draft => {
        lSet(draft.order, key, value);
      })
    ),
  setService: service =>
    set(
      produce(draft => {
        draft.order.service = service;
      })
    ),
  setProduct: (id: string | number, name: string) =>
    set(
      produce(draft => {
        draft.order.product = id;
        draft.order.product_name = name;
      })
    ),
  setAccessories: accessories =>
    set(
      produce(draft => {
        draft.order.accessories = accessories;
      })
    ),
  setJaws: jaws =>
    set(
      produce(draft => {
        draft.order.jaws = jaws;
      })
    )
}));

export default useOrderStore;
