import { orderBy } from 'lodash';

import type { IManufacturer } from '../types';

export function sortManufacturer(
  items?: { [key: string]: IManufacturer },
  topNames?: string[]
):  IManufacturer[] {
  if (!items) {
    return [];
  }

  if (!topNames) {
    return orderBy(items, 'key', 'asc');
  }

  return orderBy(items, [
    item => {
      const index = topNames.indexOf(item.key);

      return index === -1 ? Infinity : index;
    },
    'key'
  ], [
    'asc',
    'asc'
  ]);
}
