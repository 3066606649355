import type { FC } from 'react';
import React from 'react';

import FilterRent from '../components/FilterRent';
import ProductList from '../components/ProductList';

const StepProductSelect: FC = () => {
  return (
    <>
      <div className="box">
        <div className="box-content">
          <FilterRent />
          <ProductList />
        </div>
      </div>
      {/*
      // find me in RentCart.tsx
      <div className="under-box">
        <div className="under-box-right">
          <Button
            disabled={!isValid}
            className={classNames('btn-xl btn-full', {
              'btn-disabled': !isValid
            })}
            // className="btn-l btn-full"
            onClick={() => nextStep()}
            hasRightIcon
          >
            Weiter zum Versand
          </Button>
        </div>
      </div>
      */}
    </>
  );
};

export default StepProductSelect;
