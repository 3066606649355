import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import './Input.scss';

interface ITextareaProps {
  label?: string;
  name?: string;
  placeholder?: string;
  wrapperClass?: string;
  value?: string | number;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea: FC<ITextareaProps> = ({
  label,
  value,
  placeholder,
  wrapperClass,
  name,
  onChange
}) => {
  return (
    <div className={classNames('styled-input', wrapperClass)}>
      {label && (<label htmlFor={name}>{label}</label>)}
      <textarea
        id={name}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
      >{value}</textarea>
    </div>
  );
};

export default Textarea;
