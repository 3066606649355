import type { FC } from 'react';
import React from 'react';

import Progressbar from '../components/ui/Progressbar';

interface IStepConfirmationProps {
  reset: () => void;
}

const StepConfirmation: FC<IStepConfirmationProps> = ({
  reset
}) => {
  return (
    <div className="box">
      <div className="box-content">
        <Progressbar reset={reset} />
      </div>
    </div>
  );
};

export default StepConfirmation;
