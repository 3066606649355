import { sum, values } from 'lodash';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import React from 'react';

import CartPortal from '../components/CartPortal';
import ContentNavigation from '../components/ContentNavigation';
import ProductAccessories from '../components/ProductAccessories';
import ProductInfoCard from '../components/ProductInfoCard';
import ProductJaws from '../components/ProductJaws';
import ServiceProviderLogo from '../components/ServiceProviderLogo';
import BoxModal from '../components/ui/BoxModal';
import Button from '../components/ui/Button';
import ConfigContext from '../service/context/config';
import useAppStore from '../service/store/app';
import useOrderStore from '../service/store/order';
import { JAWS_FREE_DELIVERY_COUNT } from '../utils/constants';
import { scrollToRef } from '../utils/scroll';
import { getServiceProvider } from '../utils/service_provider';

const StepAccessoriesSelect: FC = () => {
  const jawsAlertRef = useRef<null | HTMLDivElement>(null);
  const batterieAlertRef = useRef<null | HTMLDivElement>(null);
  const {
    setStep
    // batteriesAlertSeen,
    // setBatteriesAlertSeen
  } = useAppStore(state => state);
  const { name: serviceProviderName } = getServiceProvider();
  const [ showJawsAlert, setShowJawsAlert ] = useState(false);
  const [ showBatteriesAlert, setShowBatteriesAlert ] = useState(false);

  const {
    accessories,
    jaws
  } = useOrderStore(state => state.order) || {};
  const sumBatteries = accessories?.batteries || 0;
  const [ batteriesAlertSeen, setBatteriesAlertSeen ] = useState(sumBatteries > 0);
  const setJaws = useOrderStore(state => state.setJaws);
  const setAccessories = useOrderStore(state => state.setAccessories);
  const config = useContext(ConfigContext);
  const sumJaws = sum(values(jaws));

  // useEffect(() => {
  //   if (
  //     accessories?.batteries
  //     && accessories.batteries > 0
  //     && !batteriesAlertSeen
  //   ) {
  //     setShowBatteriesAlert(true);
  //     setBatteriesAlertSeen(true);
  //   }
  // }, [ accessories?.batteries, setBatteriesAlertSeen, batteriesAlertSeen ]);

  const handleNextStep = (): void => {
    if (
      sumJaws < JAWS_FREE_DELIVERY_COUNT
    ) {
      setShowJawsAlert(true);

      setTimeout(() => {
        jawsAlertRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    } else {
      setStep(5);
      scrollToRef();
    }
  };

  useEffect(() => {
    if (
      !batteriesAlertSeen
      && accessories?.batteries
      && accessories.batteries > 0
    ) {
      setShowBatteriesAlert(true);
      setBatteriesAlertSeen(true);
    }
  }, [ accessories?.batteries, batteriesAlertSeen ]);

  return (
    <>
      <ContentNavigation>
        <Button
          className="btn-dark"
          onClick={handleNextStep}
          hasRightIcon
        >
          Weiter zu Adressdaten
        </Button>
      </ContentNavigation>
      <div className="box">
        {serviceProviderName && (
          <div className="box-header">
            <ServiceProviderLogo />
            Dieses Gerät wird von {serviceProviderName} bearbeitet.
          </div>
        )}
        <div className="box-content">
          <div className="form-grid">
            <div className="styled-input">
              <label>Deine Auswahl:</label>
              <div className="item-box">
                <ProductInfoCard />
              </div>
            </div>
            <div className="styled-input">
              <label>Hinweis:</label>
              <div className="item-box unstyled">
                Sie können zu ihrem Auftrag auch Zubehör anderer Hersteller beifügen und bearbeiten lassen.
              </div>
            </div>
          </div>
          <div className="form-grid">
            <div className="container-row">
              <div className="input-container-title">Zubehör</div>
              <ProductAccessories
                accessories={accessories}
                setAccessories={setAccessories}
                config={config}
              />
            </div>
          </div>
          <div className="form-grid">
            <div>
              <div className="input-container-title">Pressbacken</div>
              <ProductJaws
                jaws={jaws}
                setJaws={setJaws}
                config={config}
              />
              <div className="input-container-info">
                {/* eslint-disable-next-line max-len */}
                Ab einer Anzahl von einer Maschine und {JAWS_FREE_DELIVERY_COUNT} Pressbacken übernehmen wir Ihre Abholkosten.
              </div>
            </div>
          </div>
          <div className="box-cta">
            <Button
              onClick={handleNextStep}
              hasRightIcon
              icon="arrow-right"
            >
              Weiter zu Adressdaten
            </Button>
          </div>
          <CartPortal>
            <Button
              onClick={handleNextStep}
              hasRightIcon
              icon="arrow-right"
            >
              Weiter zu Adressdaten
            </Button>
          </CartPortal>
          {showBatteriesAlert && (
            <BoxModal
              ref={batterieAlertRef}
              text="Defekte Li-Ion Akkus sind Gefahrgut und dürfen nicht ohne weiteres versendet werden.
                  Falls sie einen defekten Li-Ionen Akku haben, dann sprechen Sie uns gerne direkt an.
                  Wir würden Ihnen dazu gerne ein kostengünstiges Austauschangebot machen."
              closeFunction={() => {
                setShowBatteriesAlert(false);
              }}
              successFunction={() => {
                setShowBatteriesAlert(false);
              }}
            />
          )}
          {showJawsAlert && (
            <BoxModal
              ref={jawsAlertRef}
              text="Wenn Sie ein Pressgerät und mindestens sechs Pressbacken abholen lassen,
                  übernehmen wir die Abholkosten für Sie."
              title="Schon gewusst?"
              btnText="Weiter zu Adressdaten"
              secondaryCtaButton="Weiteres Zubehör ergänzen"
              btnIcon="arrow-right"
              closeFunction={() => {
                setShowJawsAlert(false);
              }}
              successFunction={() => {
                setShowJawsAlert(false);
                setStep(5);
                scrollToRef();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StepAccessoriesSelect;
