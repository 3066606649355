import { filter } from 'lodash';

import type { IManufacturer } from '../types';
import { sortManufacturer } from '../utils/sorting';

export function useManufacturer(
  manufacturer?: { [key: string]: IManufacturer },
  topNames?: string[]
): {
    sortedManufacturer: IManufacturer[];
    manufacturer: IManufacturer[];
    topManufacturer: IManufacturer[];
  } {
  if (!manufacturer || !topNames) {
    return {
      sortedManufacturer: [],
      manufacturer: [],
      topManufacturer: []
    };
  }

  const sortedManufacturer = sortManufacturer(manufacturer, topNames);

  const heroBrands = topNames
    ? filter(sortedManufacturer, item => topNames.includes(item.key))
    : [];
  const brands = filter(sortedManufacturer, item => !topNames?.includes(item.key));

  return {
    sortedManufacturer,
    manufacturer: brands,
    topManufacturer: heroBrands
  };
}
