import { isString, size } from 'lodash';

import { OPTION_DELIVERY, OPTION_PICKUP } from '../forms/rent/constants';
import type { Order, OrderRent, OrderVos } from '../types';
import type { IAddress } from '../types';

import { EMAIL_PATTERN } from './constants';

export function isEmail(input: string | unknown): boolean {
  return isString(input) && EMAIL_PATTERN.test(input);
}

export function validateOrderVos(
  order?: OrderVos,
  reminderOnly = false
): boolean {
  if (!order) {
    return false;
  }

  if (
    typeof order.checkedLegal !== 'boolean'
    || typeof order.customer_number !== 'string'
    || typeof order.address !== 'object'
  ) {
    return false;
  }

  if (reminderOnly) {
    if (
      typeof order.dealer !== 'object'
    ) {
      return false;
    }
  } else {
    if (
      typeof order.appointment !== 'object'
      // || typeof order.reference_number !== 'string'
      || typeof order.jaws !== 'object'
      || typeof order.accessories !== 'object'
      || typeof order.manufacturer !== 'object'
      || typeof order.address !== 'object'
    ) {
      return false;
    }
  }

  if (
    // !validateAddress(order.address, true)
    !validateAddress(order.address, reminderOnly)
  ) {
    return false;
  }

  return true;
}

export function validateOrderRent(
  order?: OrderRent
): boolean {
  if (!order) {
    return false;
  }

  if (
    size(order.products) < 1
    || typeof order.checkedLegal !== 'boolean'
    || typeof order.customer_number !== 'string'
    || typeof order.reference_number !== 'string'
    || typeof order.delivery_type !== 'string'
    // || typeof order.deliveryAddress !== 'object'
    || typeof order.diffDeliveryAddress !== 'boolean'
    // || typeof order.pickupAddress !== 'object'
    || typeof order.diffInvoiceAddress !== 'boolean'
    || typeof order.address !== 'object'
  ) {
    return false;
  }

  const isDelivery = order.delivery_type === OPTION_DELIVERY;
  const isPickup = order.delivery_type === OPTION_PICKUP;

  if (
    // !order.service
    // // || !order.product
    // // || !order.product_name
    // || !order.description
    // // || !order.checkedLegal
    // // || !order.customer_number
    // // || !order.reference_number
    // // || !order.order_info
    // || !order.pickup_date
    // || order.number_of_parcels < 1
    // || !validateAddress(order.address, true)
    !validateAddress(order.address, true)
    || (isDelivery && order.diffDeliveryAddress && !validateAddress(order.deliveryAddress))
    || (order.diffInvoiceAddress && !validateAddress(order.invoiceAddress))
  ) {
    return false;
  }

  if (
    isDelivery
    && (!order.delivery_date || !order.delivery_option)
  ) {
    return false;
  }

  if (
    isPickup
    && (!order.pickup_date || !order.pickup_option)
  ) {
    return false;
  }

  return true;
}

export function  validateOrder(order?: Order): boolean {
  if (!order) {
    return false;
  }

  if (
    typeof order.service !== 'string'
    // || typeof order.product !== 'string'
    // || typeof order.product_name !== 'string'
    || typeof order.description !== 'string'
    || typeof order.checkedLegal !== 'boolean'
    || typeof order.jaws !== 'object'
    || typeof order.accessories !== 'object'
    || typeof order.customer_number !== 'string'
    || typeof order.reference_number !== 'string'
    || typeof order.order_info !== 'string'
    || typeof order.pickup_date !== 'string'
    || typeof order.number_of_parcels !== 'number'
    || typeof order.cost_approval !== 'number'
    // || typeof order.deliveryAddress !== 'object'
    || typeof order.diffDeliveryAddress !== 'boolean'
    // || typeof order.pickupAddress !== 'object'
    || typeof order.diffPickupAddress !== 'boolean'
    || typeof order.address !== 'object'
  ) {
    return false;
  }

  if (
    !order.service
    // || !order.product
    // || !order.product_name
    // description only exists for products not "accessory only"
    || (order.product !== 0 && !order.description)
    // || !order.checkedLegal
    // || !order.customer_number
    // || !order.reference_number
    // || !order.order_info
    || !order.pickup_date
    || order.number_of_parcels < 1
    || !validateAddress(order.address, true)
    || (order.diffDeliveryAddress && !validateAddress(order.deliveryAddress))
    || (order.diffPickupAddress && !validateAddress(order.pickupAddress))
  ) {
    return false;
  }

  return true;
}

export function validateAddress(address?: IAddress, checkCountry = false): boolean {
  if (!address) {
    return false;
  }

  if (
    typeof address.company !== 'string'
    || typeof address.firstname !== 'string'
    || typeof address.lastname !== 'string'
    || typeof address.street !== 'string'
    || typeof address.house_number !== 'string'
    || typeof address.salutation !== 'string'
    || typeof address.zip !== 'string'
    || typeof address.city !== 'string'
    || typeof address.phone !== 'string'
    || typeof address.email !== 'string'
    // || typeof address.fax !== 'string'
  ) {
    return false;
  }

  if (
    !address.company
    || !address.firstname
    || !address.lastname
    || !address.street
    || !address.house_number
    || !address.salutation
    || !address.zip
    || !address.city
    || !address.phone
    || !address.email
    || !isEmail(address.email)
    // || typeof address.fax !== 'string'
  ) {
    return false;
  }

  if (checkCountry) {
    if (typeof address.country !== 'string') {
      return false;
    }

    if (!address.country) {
      return false;
    }
  }

  return true;
}
