import classNames from 'classnames';
import { camelCase } from 'lodash';
import type { FC } from 'react';
import { useContext } from 'react';
import { useRef } from 'react';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Icon from '../../../components/ui/Icon';
import ConfigRentContext from '../../../service/context/config-rent';
import useAppStoreRent from '../../../service/store/appRent';
import useOrderStoreRent from '../../../service/store/orderRent';
import { findNextWeekday, isWeekday } from '../../../utils/date';
import { formatCurrency } from '../../../utils/format';
import { OPTION_DELIVERY, OPTION_PICKUP } from '../constants';

import Address from './../../../components/Address';
import AddressToggle from './../../../components/AddressToggle';
import ContentNavigation from './../../../components/ContentNavigation';
import Button from './../../../components/ui/Button';
import './../../../components/ui/Input.scss';
import { scrollToRef } from './../../../utils/scroll';
import { validateOrderRent } from './../../../utils/validate';

interface IStepCustomerDataProps {
  placeOrder?: () => Promise<void>;
}

const StepCustomerData: FC<IStepCustomerDataProps> = ({
  placeOrder
}) => {
  const {
    delivery_options,
    pickup_options
  } = useContext(ConfigRentContext) || {};
  const errorRef = useRef<null | HTMLDivElement>(null);
  const {
    showErrors,
    setShowErrors,
    setStep,
    step
  } = useAppStoreRent(state => state);
  const order = useOrderStoreRent(state => state.order);
  const {
    customer_number,
    reference_number,
    address,
    diffDeliveryAddress,
    deliveryAddress,
    diffInvoiceAddress,
    invoiceAddress,
    delivery_date,
    pickup_date,
    delivery_type,
    delivery_option,
    pickup_option
  } = order || {};
  const setOrderValue = useOrderStoreRent(state => state.setValue);
  const validOrder = validateOrderRent(order);

  const parseDateString = (fallback: Date, dateString?: string): Date => {
    if (!dateString) {
      return fallback;
    }

    const [
      day, month, year
    ] = dateString.split('.').map(part => parseInt(part, 10));

    try {
      return new Date(year, month - 1, day);
    } catch {
      return fallback;
    }
  };

  const today = findNextWeekday(new Date());
  const deliveryDate = delivery_date ? parseDateString(today, delivery_date) : undefined;
  const pickupDate = pickup_date ? parseDateString(today, pickup_date) : undefined;

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    setOrderValue(event.target.value, event.target.name);
  };

  const handleToggleAddress = (propertyToSet: string, visible: boolean): void => {
    setOrderValue(visible, camelCase(`diff ${propertyToSet}`));
  };

  const handleCtaClick = (): void => {
    if (validOrder && placeOrder) {
      void placeOrder();
      scrollToRef();
    } else {
      setShowErrors(true);
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const isDelivery = delivery_type === OPTION_DELIVERY;
  const isPickup = delivery_type === OPTION_PICKUP;

  return (
    <>
      <ContentNavigation
        step={step}
        setStep={setStep}
        showBackButton
      >
        <Button
          className={classNames('btn-dark', {
            'btn-disabled': !validOrder
          })}
          onClick={handleCtaClick}
          hasRightIcon
          // disabled={!validOrder}
        >
          Jetzt Miete beauftragen
        </Button>
      </ContentNavigation>
      <div className="box">
        <div className="box-content">
          <div ref={errorRef}>
            {showErrors && !validOrder && (
              <div className="error-box">
                Bitte fülle aller Pflichtfelder aus.
              </div>
            )}
          </div>
          <div>
            <div className="styled-input">
              <label>Versand</label>
              <div className="buttons-wrap">
                <button
                  className={classNames({ active: isDelivery })}
                  onClick={() => setOrderValue(OPTION_DELIVERY, 'delivery_type')}
                >
                  Senden Sie mir alles zu
                </button>
                <button
                  className={classNames({ active: isPickup })}
                  onClick={() => setOrderValue(OPTION_PICKUP, 'delivery_type')}
                >
                  Ich hole alles bei ToolService ab
                </button>
              </div>
            </div>
          </div>
          {isDelivery && (
            <div className="form-grid">
              <div>
                <div className="styled-input-datepicker">
                  <label>
                    Bitte spätestens versenden am *
                  </label>
                  <div className="styled-input-datepicker-input-wrap">
                    <Icon icon="calendar" />
                    <DatePicker
                      dateFormat="dd.MM.yyyy"
                      selected={deliveryDate}
                      onChange={date => {
                        setOrderValue((
                          date || today
                        ).toLocaleDateString('de-DE'), 'delivery_date');
                      }}
                      className={classNames({
                        'has-error-input': showErrors && !delivery_date
                      })}
                      filterDate={isWeekday}
                      minDate={today}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className={classNames('styled-input', {
                  'has-error': showErrors && !delivery_option
                })}>
                  <label htmlFor="delivery_option">Versandart *</label>
                  <select
                    name="delivery_option"
                    value={delivery_option?.id}
                    onChange={event => {
                      const selectedId = event.target.value;
                      const selected = delivery_options?.find(option => option.id === selectedId);

                      if (selected) {
                        setOrderValue(selected, 'delivery_option');
                      }
                    }}
                  >
                    {delivery_options?.map(option => (
                      <option
                        key={option.id}
                        value={option.id}
                      >
                        {option.name} ({formatCurrency(option.cost)})
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          {isPickup && (
            <div className="form-grid">
              <div>
                <div className="styled-input-datepicker">
                  <label>
                    Abholdatum *
                  </label>
                  <div className="styled-input-datepicker-input-wrap">
                    <Icon icon="calendar" />
                    <DatePicker
                      dateFormat="dd.MM.yyyy"
                      selected={pickupDate}
                      onChange={date => {
                        setOrderValue((
                          date || today
                        ).toLocaleDateString('de-DE'), 'pickup_date');
                      }}
                      className={classNames({
                        'has-error-input': showErrors && !pickup_date
                      })}
                      filterDate={isWeekday}
                      minDate={today}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className={classNames('styled-input', {
                  'has-error': showErrors && !pickup_option
                })}>
                  <label htmlFor="pickup_option">Abholort *</label>
                  <select
                    name="pickup_option"
                    value={pickup_option?.id}
                    onChange={event => {
                      const selectedId = event.target.value;
                      const selected = pickup_options?.find(option => option.id === selectedId);

                      if (selected) {
                        setOrderValue(selected, 'pickup_option');
                      }
                    }}
                  >
                    {pickup_options?.map(option => (
                      <option
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          <Address
            showError={showErrors}
            propertyKey="address"
            address={address}
            onChange={handleInputChange}
            customerNumber={customer_number}
            showCustomerNumber
            referenceNumber={reference_number}
            showCountrySelect
            showReferenceNumber
          />
          {isDelivery && (
            <AddressToggle
              showError={showErrors}
              title="Abweichende Lieferadresse?"
              propertyKey="deliveryAddress"
              address={deliveryAddress}
              showAddress={diffDeliveryAddress}
              onChange={handleInputChange}
              toggleFunction={handleToggleAddress}
            />
          )}
          <AddressToggle
            showError={showErrors}
            title="Abweichende Rechnungsadresse?"
            propertyKey="invoiceAddress"
            address={invoiceAddress}
            showAddress={diffInvoiceAddress}
            onChange={handleInputChange}
            toggleFunction={handleToggleAddress}
          />
        </div>
      </div>
    </>
  );
};

export default StepCustomerData;
