import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import { UNKNOWN } from '../utils/constants';

import './ProductItem.scss';
import Button from './ui/Button';
import Icon from './ui/Icon';
import type { IImageProps } from './ui/Image';
import Image from './ui/Image';

interface IProductItemProps {
  name: string;
  image?: string;
  btnText?: string;
  type?: IImageProps['type'];
  active?: boolean;
  onClick?: () => void;
}

const ProductItem: FC<IProductItemProps> = ({
  name,
  image,
  btnText = '',
  type = 'products',
  active = false,
  onClick
}) => {
  const isUnknown = name.toLowerCase().endsWith(UNKNOWN);

  return (
    <div
      onClick={onClick}
      className={classNames('product-item', {
        active
      })}
    >
      {!image || isUnknown ? (
        <div className="product-image unknown">
          <Icon icon="question" />
        </div>
      ) : (
        <>
          {image && (
            <div className="product-image">
              <Image
                url={image}
                alt={name}
                title={name}
                type={type}
                className="product-item-image"
              />
            </div>
          )}
        </>
      )}
      {name}
      {btnText && (
        <Button
          icon="plus"
          hasLeftIcon
        >
          {btnText}
        </Button>
      )}
    </div>
  );
};

export default ProductItem;
