import { map } from 'lodash';
import type { FC } from 'react';
import { useState } from 'react';
import React, { useContext } from 'react';
import DatePicker from 'react-datepicker';

import Icon from '../../../components/ui/Icon';
import ConfigVosContext from '../../../service/context/config-vos';
import useAppStoreVos from '../../../service/store/appVos';
import { isWeekday } from '../../../utils/date';

import './../../../../src/components/Filter.scss';

const AppointmentFilter: FC = () => {
  const [ selectedDate, setSelectedDate ] = useState<Date>(); // Initialize with today's date
  const appContext = useContext(ConfigVosContext);
  const { cities, customers } = appContext || {};
  // const filter = useAppStore(state => state.filter);
  const { city, customer } = useAppStoreVos(state => state.filter);
  const setFilter = useAppStoreVos(state => state.setFilter);

  const today = new Date();

  today.setDate(today.getDate() + 1);

  return (
    <div className="filter-wrap">
      <div className="filter-item">
        <label>Datum</label>
        <div className="filter-item-serach">
          <Icon icon="calendar" />
          <DatePicker
            dateFormat="dd.MM.yyyy"
            selected={selectedDate}
            onChange={date => {
              setSelectedDate(date || new Date());
              setFilter('date', date?.toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              }) || '');
              // setOrderValue(date?.toLocaleDateString('de-DE') || '', 'pickup_date');
            }}
            filterDate={isWeekday}
            minDate={today}
          />
        </div>
      </div>
      <div className="filter-item">
        <label>Fachhandel</label>
        <select
          onChange={event => setFilter('customer', event.target.value)}
        >
          <option value="">bitte wählen</option>
          {map(customers, option => (
            <option
              key={option}
              value={option}
              selected={option === customer}
            >
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className="filter-item">
        <label>Stadt</label>
        <select
          onChange={event => setFilter('city', event.target.value)}
        >
          <option value="">bitte wählen</option>
          {map(cities, option => (
            <option
              key={option}
              value={option}
              selected={city === option}
            >
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default AppointmentFilter;
