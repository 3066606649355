import { createContext } from 'react';

import type { AppConfig } from '../../types';

export type TConfigContext = {
  config: AppConfig | null;
};

const ConfigContext = createContext<AppConfig | null>(null);

export default ConfigContext;
