import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import { headlineContentService, MAX_STEPS_SERVICE } from '../forms/service/constants';

import './Headlines.scss';
import Icon from './ui/Icon';

export interface IHeadlineContent {
  title: string;
  lead: string;
  back?: string;
}

interface IHeadlinesProps {
  title?: string;
  lead?: string;
  back?: string;
  headlines?: IHeadlineContent[];
  maxSteps?: number;
  reset?: () => void;
  setStep: (step: number) => void;
  step: number;
}

const Headlines: FC<IHeadlinesProps> = ({
  reset,
  headlines = headlineContentService,
  maxSteps = MAX_STEPS_SERVICE,
  step,
  setStep
}) => {
  const contentToUse = headlines[step - 1];
  const {
    title,
    lead,
    back
  } = contentToUse || {};
  const hasBack = back && step > 0;

  if (!title && !lead) {
    return null;
  }

  const handleBack = (): void => {
    if (step === maxSteps && reset) {
      reset();
    } else {
      setStep(step - 1);
    }
  };

  return (
    <div className={classNames('headlines', {
      'no-back': !hasBack
    })}>
      {hasBack && (
        <span className="headlines-back" onClick={handleBack}>
          <Icon icon="chevron-left" />
          {back}
        </span>
      )}
      {title && <h1>{title}</h1>}
      {lead && <p>{lead}</p>}
    </div>
  );
};

export default Headlines;
