import classNames from 'classnames';
import type { FC } from 'react';

import './AppointmentItem.scss';
import DateIcon from './DateIcon';
import Icon from './ui/Icon';

interface IAppointmentItem {
  title: string;
  address: string;
  date?: string;
  day?: string;
  month?: string;
  standalone?: boolean;
  showDate?: boolean;
  onClick?: () => void;
}

const AppointmentItem: FC<IAppointmentItem> = ({
  standalone = false,
  showDate = false,
  onClick: handleClick,
  title,
  address,
  date,
  day,
  month
}) => {
  const isClickable = !standalone && handleClick;

  return (
    <div
      className={classNames('appointment-item', {
        clickable: isClickable,
        'has-bell': !date
      })}
      onClick={() => isClickable && handleClick()}
    >
      {(showDate || !standalone) && day && month && (
        <DateIcon day={day} month={month} />
      )}
      <div className="appointment-item-info">
        <div className="title">{title}</div>
        <div className="meta">
          {!standalone && date && (
            <div>
              <Icon icon="calendar" /> {date}
            </div>
          )}
          <div>
            {address}
          </div>
        </div>
      </div>
      {!standalone && (
        <div className="action-icon">
          {date ? (
            <Icon icon="chevron-right" />
          ) : (
            <>
              <Icon icon="bell" />
              Erinnern
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AppointmentItem;
