import classNames from 'classnames';
import type { FC } from 'react';
import { useContext } from 'react';
import React, { useState, useEffect } from 'react';

import CartAccessories from './components/CartAccessories';
import CartMachine from './components/CartMachine';
import CartTransport from './components/CartTransport';
import './components/ui/Button.scss';
import ConfigContext from './service/context/config';
import useAppStore from './service/store/app';
import useOrderStore from './service/store/order';

const Cart: FC = () => {
  const [ tab, setTab ] = useState(0);
  const { step } = useAppStore(state => state);
  const {
    accessories, jaws
  } = useOrderStore(state => state.order) || {};
  const config = useContext(ConfigContext);

  useEffect(() => {
    if (step === 2) {
      setTab(0);
    } else if (step === 4) {
      setTab(1);
    } else if (step === 5) {
      setTab(2);
    }
  }, [ step ]);

  return (
    <div>
      <div className="box has-no-shadow">
        <div className="box-content">
          <div className="tab-navi-wrap">
            <div className="tab-navi">
              <button
                className={classNames('btn', {
                  inactive: tab !== 0
                })}
                onClick={() => setTab(0)}
              >
                Maschine
              </button>
              <button
                className={classNames('btn', {
                  inactive: tab !== 1
                })}
                onClick={() => setTab(1)}
              >
                Zubehör
              </button>
              <button
                className={classNames('btn', {
                  inactive: tab !== 2
                })}
                onClick={() => setTab(2)}
              >
                Transport
              </button>
            </div>
          </div>
          {tab === 0 && (<CartMachine />)}
          {tab === 1 && (
            <CartAccessories
              accessories={accessories}
              jaws={jaws}
              config={config}
            />
          )}
          {tab === 2 && (<CartTransport />)}
        </div>
      </div>
      <div className="box-hint">
        <div>
          Alle Preise zzgl. Mehrwertsteuer
        </div>
        <div className="box-hint-logo">
          Powered by <img src="./toolservice-logo-light.png" alt="Tool Service Logo" height="20px" />
        </div>
      </div>
    </div>
  );
};

export default Cart;
