import type { FC } from 'react';
import React from 'react';

import './ServiceCard.scss';
import Image from './ui/Image';

interface IServiceCardProps {
  image: string;
  imageIcon: string;
  type: string;
  hint?: string;
  title: string;
  description: string;
  onClick: (value: string) => void;
}

const ServiceCard: FC<IServiceCardProps> = ({
  type,
  image,
  imageIcon,
  hint,
  title,
  description,
  onClick
}) => {
  return (
    <div
      className="service-card"
      onClick={() => onClick(type)}
    >
      {hint && (
        <div className="service-card-hint">
          <span>{title}</span>
        </div>
      )}
      <div className="service-content">
        <Image
          type="services"
          url={image}
          alt={title}
        />
        <div className="service-icon">
          <Image
            type="services"
            url={imageIcon}
            alt={title}
          />
        </div>
        <div className="service-title">{hint}</div>
        <div className="service-description">{description}</div>
      </div>
      <div className="service-footer">
        Service nutzen
      </div>
    </div>
  );
};

export default ServiceCard;
