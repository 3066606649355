import classNames from 'classnames';
import { camelCase } from 'lodash';
import type { FC } from 'react';
import { useRef } from 'react';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Address from '../components/Address';
import AddressToggle from '../components/AddressToggle';
import CartPortal from '../components/CartPortal';
import ContentNavigation from '../components/ContentNavigation';
import Button from '../components/ui/Button';
import Icon from '../components/ui/Icon';
import Image from '../components/ui/Image';
import Input from '../components/ui/Input';
import '../components/ui/Input.scss';
import useOrderStore from '../service/store/order';
import { findNextWeekday, isWeekday } from '../utils/date';
import { scrollToRef } from '../utils/scroll';
import { validateOrder } from '../utils/validate';

interface IStepCustomerDataProps {
  onClick?: () => Promise<void>;
}

const StepCustomerData: FC<IStepCustomerDataProps> = ({
  onClick
}) => {
  const errorRef = useRef<null | HTMLDivElement>(null);
  const [ showErrors, setShowErrors ] = useState(false);
  const order = useOrderStore(state => state.order);
  const {
    number_of_parcels,
    customer_number,
    reference_number,
    order_info,
    pickup_date,
    address,
    diffDeliveryAddress,
    deliveryAddress,
    diffPickupAddress,
    pickupAddress
  } = order || {};
  const setOrderValue = useOrderStore(state => state.setValue);
  const validOrder = validateOrder(order);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    setOrderValue(event.target.value, event.target.name);
  };

  const handleToggleAddress = (propertyToSet: string, visible: boolean): void => {
    setOrderValue(visible, camelCase(`diff ${propertyToSet}`));
  };

  const today = findNextWeekday(new Date());
  const [ selectedDate, setSelectedDate ] = useState(today);

  const handleCtaClick = (): void => {
    if (validOrder && onClick) {
      void onClick();
      scrollToRef();
    } else {
      setShowErrors(true);
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <ContentNavigation>
        <Button
          className={classNames('btn-dark', {
            'btn-disabled': !validOrder
          })}
          onClick={handleCtaClick}
          hasRightIcon
          // disabled={!validOrder}
        >
          Jetzt Abholung beauftragen
        </Button>
      </ContentNavigation>
      <div className="box">
        <div className="box-content">
          <div ref={errorRef}>
            {showErrors && !validOrder && (
              <div className="error-box">
                Bitte fülle aller Pflichtfelder aus.
              </div>
            )}
          </div>
          <div className="form-grid fix-gap">
            <div>
              <div className="item-box">
                <Input
                  type="number"
                  label="Anzahl Pakete"
                  name="number_of_parcels"
                  value={number_of_parcels}
                  onChange={handleInputChange}
                  wrapperClass={classNames({
                    'has-error': showErrors && !number_of_parcels
                  })}
                  min={1}
                  inline
                />
              </div>
            </div>
            <div>
              <div className="item-box hint">
                <Image
                  type="content"
                  url="package-hint.svg"
                  alt="package hint"
                />
                Bitte beachte, dass jedes Paket maximal 25KG wiegen sollte.
              </div>
            </div>
          </div>
          <div className="form-grid">
            <div>
              <div className="styled-input-datepicker">
                <label>
                  Wann sollen wir vorbeikommen?
                </label>
                <div className="styled-input-datepicker-input-wrap">
                  <Icon icon="calendar" />
                  <DatePicker
                    dateFormat="dd.MM.yyyy"
                    selected={selectedDate}
                    onChange={date => {
                      setSelectedDate(date || new Date());
                      setOrderValue(date?.toLocaleDateString('de-DE') || '', 'pickup_date');
                    }}
                    className={classNames({
                      'has-error': showErrors && !pickup_date
                    })}
                    filterDate={isWeekday}
                    minDate={today}
                  />
                </div>
              </div>
            </div>
            <div>
              <Input
                value={order_info}
                label="Hinweise zur Abholung?"
                placeholder="Mittagspausen, Abstellplatz etc?"
                name="order_info"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <Address
            showError={showErrors}
            propertyKey="address"
            address={address}
            onChange={handleInputChange}
            customerNumber={customer_number}
            showCustomerNumber
            referenceNumber={reference_number}
            showCountrySelect
            showReferenceNumber
          />
          <AddressToggle
            showError={showErrors}
            title="Abweichende Abholadresse?"
            propertyKey="pickupAddress"
            address={pickupAddress}
            showAddress={diffPickupAddress}
            onChange={handleInputChange}
            toggleFunction={handleToggleAddress}
          />
          <AddressToggle
            showError={showErrors}
            title="Abweichende Rücksendeadresse?"
            propertyKey="deliveryAddress"
            address={deliveryAddress}
            showAddress={diffDeliveryAddress}
            onChange={handleInputChange}
            toggleFunction={handleToggleAddress}
          />
          <div className="box-cta">
            <Button
              className={classNames('btn-xl', {
                'btn-disabled': !validOrder
              })}
              onClick={handleCtaClick}
              hasRightIcon
              icon="arrow-right"
              // disabled={!validOrder}
            >
              Jetzt Abholung beauftragen
            </Button>
          </div>
          <CartPortal>
            <Button
              className={classNames('btn-xl', {
                'btn-disabled': !validOrder
              })}
              onClick={handleCtaClick}
              hasRightIcon
              icon="arrow-right"
              // disabled={!validOrder}
            >
              Jetzt Abholung beauftragen
            </Button>
          </CartPortal>
          <div className="box-legal">
            Mit deiner Bestellung erklärst du dich mit unseren AGB, Datenschutzbestimmungen
            und der Widerrufsbelehrung einverstanden.
          </div>
        </div>
      </div>
    </>
  );
};

export default StepCustomerData;
