import type { FC } from 'react';
import React from 'react';

import AppointmentItem from '../../../components/AppointmentItem';
import useOrderStoreVos from '../../../service/store/orderVos';
import type { IAppointment, IDealer } from '../../../types';

const CartAppointment: FC = () => {
  const { order } = useOrderStoreVos(state => state);
  const { dealer, appointment } = order || {};

  const selectedDealer = dealer || appointment;

  const isAppointmentType = (data?: IAppointment | IDealer): data is IAppointment => {
    return (data as IAppointment)?.day !== undefined;
  };
  const isAppointment = isAppointmentType(selectedDealer);

  return (
    <div>
      {selectedDealer ? (
        <AppointmentItem
          title={selectedDealer?.customer}
          address={selectedDealer?.address}
          day={isAppointment ? selectedDealer.day : ''}
          month={isAppointment ? selectedDealer.month : ''}
          showDate
          standalone
        />
      ) : (
        <div>
          <em>Kein Händler ausgewählt</em>
        </div>
      )}
    </div>
  );
};

export default CartAppointment;
