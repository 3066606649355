import { toNumber } from 'lodash';
import type { FC } from 'react';
import { useContext } from 'react';
import React from 'react';

import ConfigContext from '../service/context/config';
import useAppStore from '../service/store/app';
import useOrderStore from '../service/store/order';
import { COST_APPROVAL_FREE_LIMIT } from '../utils/constants';
import { formatCurrency } from '../utils/format';

import './../Cart.scss';
import ProductInfoCard from './ProductInfoCard';

const CartMachine: FC = () => {
  const appContext = useContext(ConfigContext);
  const { preparation_cost_estimates } = appContext || {};
  const selectedProduct = useAppStore(state => state.selectedProduct);
  const {
    cost_approval
  } = useOrderStore(state => state.order) || {};

  if (!selectedProduct) {
    return (
      <div>
        <em>Noch keine Maschine gewählt.</em>
      </div>
    );
  }

  return (
    <div>
      <div className="cart-head">
        <ProductInfoCard />
      </div>
      <div className="cart-row">
        <div className="cart-row-inner">
          <div className="name">Inspektion:</div>
          <div className="currency">{formatCurrency(selectedProduct.price)}</div>
        </div>
        <div className="cart-row-inner">
          <div className="name">Kostenfreigabe für sofortige Reparatur:</div>
          <div className="currency">+ {formatCurrency(toNumber(cost_approval))}</div>
        </div>
      </div>
      {preparation_cost_estimates && (
        <div className="cart-row">
          <div className="cart-row-inner">
            <div className="name">Kostenvoranschlagserstellung:</div>
            <div className="currency">+ {formatCurrency(preparation_cost_estimates)}</div>
          </div>
          {toNumber(cost_approval) >= COST_APPROVAL_FREE_LIMIT && (
            <div className="cart-row-inner text-m text-primary">
              <div className="name">Wird durch höhe der Kostenfreigabe erstattet:</div>
              <div className="currency">-{formatCurrency(preparation_cost_estimates)}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CartMachine;
