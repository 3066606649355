import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import './Button.scss';
import Icon from './Icon';

interface IButtonProps {
  text?: string;
  className?: string;
  children?: React.ReactNode;
  resetBtn?: boolean;
  hasLeftIcon?: boolean;
  icon?: string;
  hasRightIcon?: boolean;
  disabled?: boolean;
  isGhost?: boolean;
  onClick?: () => void;
}

const Button: FC<IButtonProps> = ({
  text,
  className,
  onClick,
  children,
  icon,
  hasLeftIcon,
  hasRightIcon,
  disabled = false,
  resetBtn = false,
  isGhost
}) => {
  const contentToUse = text || children;

  return (
    <button
      onClick={onClick}
      className={classNames(className, {
        btn: !resetBtn,
        'btn--reset': resetBtn,
        'btn--left-icon': hasLeftIcon,
        'btn--right-icon': hasRightIcon,
        [`btn--icon-${icon}`]: (hasLeftIcon || hasRightIcon) && icon,
        'btn--ghost': isGhost
      })}
      disabled={disabled}
    >
      {hasLeftIcon &&  <Icon icon={icon || 'chevron-left'} />}
      {contentToUse}
      {hasRightIcon &&  <Icon icon={icon || 'chevron-right'} />}
    </button>
  );
};

export default Button;
