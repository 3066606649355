import classNames from 'classnames';
import { compact } from 'lodash';
import type { FC } from 'react';
import React, { useContext } from 'react';

import '../../../../src/components/Filter.scss';
import Icon from '../../../../src/components/ui/Icon';
import ConfigRentContext from '../../../service/context/config-rent';
import useAppStoreRent from '../../../service/store/appRent';

function separateAndSortManufacturers(
  heroManus?: string[],
  manus?: string[]
): { heroSorted: string[]; restSorted: string[] } {
  if (!manus || !heroManus) {
    return { heroSorted: [], restSorted: [] };
  }

  const heroManusNormalized = heroManus.map(man => man.toLowerCase());

  const heroSorted = manus
    .filter(man => heroManusNormalized.includes(man.toLowerCase()))
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())); // Sort case-insensitively

  const restSorted = manus
    .filter(man => !heroManusNormalized.includes(man.toLowerCase()))
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())); // Sort case-insensitively

  return { heroSorted, restSorted };
}

const FilterRent: FC = () => {
  const appContext = useContext(ConfigRentContext);
  const { manufacturers, system, type, material, hero_manufacturers } = appContext || {};
  const {
    filter,
    // setFilter,
    setFilterAdvanced,
    availableFilter
  } = useAppStoreRent(state => state);
  const {
    search,
    manufacturer,
    material: materialFilter,
    system: systemFilter,
    type: typeFilter
  } = filter;

  const hasFilter = search || manufacturer || materialFilter || systemFilter || typeFilter;
  const manufacturerList = compact(manufacturers);
  const materialList = compact(material);
  const systemList = compact(system);
  const typeList = compact(type);
  const { heroSorted, restSorted } = separateAndSortManufacturers(hero_manufacturers, manufacturerList);

  return (
    <div>
      <div className="filter-wrap filter-wrap-rent">
        <div className="filter-item">
          <label>Gerätebezeichnung</label>
          <div className={classNames('filter-item-serach', {
            // disabled: !manufacturer
          })}>
            <Icon icon="search" />
            <input
              onChange={event => setFilterAdvanced('search', event.target.value)}
              type="text"
              value={search}
              placeholder="Suche"
              // disabled={!manufacturer}
            />
          </div>
        </div>
        <div className={classNames('filter-item', {
          // 'filter-item-disabled': !manufacturer
        })}>
          <label>Produkt-Typ</label>
          <select
            onChange={event => setFilterAdvanced('type', event.target.value)}
            value={typeFilter}
          >
            <option value="">bitte wählen</option>
            {typeList.map(typeItem => {
              const isHidden = !availableFilter?.type?.includes(typeItem);

              return (
                <option
                  key={typeItem}
                  value={typeItem}
                  disabled={isHidden}
                  className={classNames({
                    'ui-hidden': isHidden
                  })}
                >
                  {typeItem}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classNames('filter-item', {
          // 'filter-item-disabled': !manufacturer
        })}>
          <label>Werkstoff</label>
          <select
            onChange={event => setFilterAdvanced('material', event.target.value)}
            value={materialFilter}
          >
            <option value="">bitte wählen</option>
            {materialList.map(materialItem => {
              const isHidden = !availableFilter?.material?.includes(materialItem);

              return (
                <option
                  key={materialItem}
                  value={materialItem}
                  disabled={isHidden}
                  className={classNames({
                    'ui-hidden': isHidden
                  })}
                >
                  {materialItem}
                </option>
              );
            })}
          </select>
        </div>
        <div className="filter-item">
          <label>Marke/Hersteller</label>
          <select
            onChange={event => setFilterAdvanced('manufacturer', event.target.value)}
            value={manufacturer}
          >
            <option value="">bitte wählen</option>
            {heroSorted.length > 0 && (
              <>
                {heroSorted.map(manufacturerItem => {
                  const isHidden = !availableFilter?.manufacturer?.includes(manufacturerItem);

                  return (
                    <option
                      key={manufacturerItem}
                      value={manufacturerItem}
                      disabled={isHidden}
                      className={classNames({
                        'ui-hidden': isHidden
                      })}
                    >
                      {manufacturerItem}
                    </option>
                  );
                })}
                <option value="">------</option>
              </>
            )}
            {restSorted.map(manufacturerItem => {
              const isHidden = !availableFilter?.manufacturer?.includes(manufacturerItem);

              return (
                <option
                  key={manufacturerItem}
                  value={manufacturerItem}
                  disabled={isHidden}
                  className={classNames({
                    'ui-hidden': isHidden
                  })}
                >
                  {manufacturerItem}
                </option>
              );
            })}
          </select>
        </div>
        <div className={classNames('filter-item', {
          // 'filter-item-disabled': !manufacturer
        })}>
          <label>System</label>
          <select
            onChange={event => setFilterAdvanced('system', event.target.value)}
            value={systemFilter}
          >
            <option value="">bitte wählen</option>
            {systemList.map(systemItem => {
              const isHidden = !availableFilter?.system?.includes(systemItem);

              return (
                <option
                  key={systemItem}
                  value={systemItem}
                  disabled={isHidden}
                  className={classNames({
                    'ui-hidden': isHidden
                  })}
                >
                  {systemItem}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="filter-reset">
        <button
          className={classNames('btn--reset', {
            disabled: !hasFilter
          })}
          onClick={() => setFilterAdvanced('reset', '')}
        >
          Filter zurücksetzen
        </button>
      </div>
    </div>
  );
};

export default FilterRent;
