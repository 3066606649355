export const UNKNOWN = 'unbekannt';

// eslint-disable-next-line no-control-regex,max-len
export const EMAIL_PATTERN = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const TOOL_SERVICE_NAME = 'ToolService';
export const TOOL_SERVICE_KEY = 'toolservice';
export const TOOL_SERVICE_SMALL_IMAGE = 'toolservice_small.png';
export const TOOL_SERVICE_KEY_EXTENSION = 'svg';

export const JAWS_FREE_DELIVERY_COUNT = 6;

export const COST_APPROVAL_FREE_DEFAULT = 250;
export const COST_APPROVAL_FREE_LIMIT = 250;

export const DEFAULT_DELIVERY_COUNTRY = 'Deutschland';
