import { createContext } from 'react';

import type { AppConfigVos } from '../../types';

export type TConfigVosContext = {
  config: AppConfigVos | null;
};

const ConfigVosContext = createContext<AppConfigVos | null>(null);

export default ConfigVosContext;
