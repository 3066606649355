import type { FC } from 'react';
import React from 'react';
import type { IconProps } from 'react-icomoon';
import IcoMoon from 'react-icomoon';

// @ts-ignore dajskldjsakld das
import iconSet from './selection.json';

const Icon: FC<IconProps> = props => {
  return (
    <IcoMoon iconSet={iconSet} {...props} />
  );
};

export default Icon;
