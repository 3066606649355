import { capitalize, groupBy, map } from 'lodash';
import type { FC } from 'react';
import React, { useContext } from 'react';

import { useManufacturer } from '../lib/manufacturer';
import ConfigContext from '../service/context/config';
import useAppStore from '../service/store/app';
import useOrderStore from '../service/store/order';
import type { Product } from '../types';
import { UNKNOWN } from '../utils/constants';
import { scrollToRef } from '../utils/scroll';

import ProductItem from './ProductItem';
import './ProductList.scss';
import Icon from './ui/Icon';
import Image from './ui/Image';

const ProductList: FC = () => {
  const appContext = useContext(ConfigContext);
  const { products, manufacturers, hero_manufacturers } = appContext || {};
  const {
    filter: { search, manufacturer, type, color },
    setSelectedProduct
  } = useAppStore(state => state);
  const {
    product
  } = useOrderStore(state => state.order) || {};
  const setFilter = useAppStore(state => state.setFilter);
  const setProduct = useOrderStore(state => state.setProduct);
  const setStep = useAppStore(state => state.setStep);
  const filteredProducts = products?.filter(productItem => {
    if (
      search
      && (
        !productItem.name.toLowerCase().includes(search.toLowerCase())
        && !productItem.id.includes(search)
      )
    ) {
      return false;
    }

    if (manufacturer
      && manufacturer !== UNKNOWN
      && productItem.manufacturer !== manufacturer
    ) {
      return false;
    }

    if (type && productItem.type_of_drive !== type) {
      return false;
    }

    if (color && productItem.color !== color) {
      return false;
    }

    return true;
  });
  const groupedProducts = groupBy(filteredProducts, 'manufacturer_key');
  const {
    manufacturer: brands,
    topManufacturer: heroBrands
  } = useManufacturer(manufacturers, hero_manufacturers);

  const handleProductCardClick = (productItem: Product): void => {
    setProduct(productItem.id, productItem.name);
    setSelectedProduct(productItem);
    setStep(3);
    scrollToRef();
  };

  return (
    <>
      {manufacturer === '' ? (
        <div className="manufacturer-group-container">
          <div className="manufacturer-group">
            <div className="manufacturer-group-head">
              <span>
                Herstellerauswahl
              </span>
            </div>
            <div className="manufacturer-list">
              {heroBrands && (
                <>
                  {map(heroBrands, manufacturerItem => (
                    <div
                      className="manufacturer-item"
                      key={manufacturerItem.key}
                      onClick={() => setFilter('manufacturer', manufacturerItem.name)}
                    >
                      <Image
                        url={`${manufacturerItem.key}.svg`}
                        alt={manufacturerItem.name}
                        type="manufacturer"
                      />
                    </div>
                  ))}
                  <hr />
                </>
              )}
              {brands && map(brands, manufacturerItem => (
                <div
                  className="manufacturer-item"
                  key={manufacturerItem.key}
                  onClick={() => setFilter('manufacturer', manufacturerItem.name)}
                >
                  <Image
                    url={`${manufacturerItem.key}.svg`}
                    alt={manufacturerItem.name}
                    type="manufacturer"
                  />
                </div>
              ))}
              <div
                className="manufacturer-item"
                onClick={() => setFilter('manufacturer', UNKNOWN)}
              >
                <Icon icon="question" /> {capitalize(UNKNOWN)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="manufacturer-group-container">
          {groupedProducts && map(groupedProducts, (group, key) => (
            <div key={key} className="manufacturer-group">
              <div className="manufacturer-group-head">
                <span>
                  <Image
                    url={`${key}.svg`}
                    alt={`${key} Logo`}
                    type="manufacturer"
                  />
                </span>
              </div>
              <div className="product-list">
                {map(group, productItem => (
                  <ProductItem
                    key={productItem.id}
                    name={productItem.name}
                    image={productItem.image}
                    active={productItem.id === product}
                    onClick={() => handleProductCardClick(productItem)}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ProductList;
