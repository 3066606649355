import type { FC, PropsWithChildren } from 'react';
import React from 'react';

import useAppStore from '../service/store/app';

import './ContentNavigation.scss';
import Button from './ui/Button';
import Icon from './ui/Icon';

interface IContentNavigation {
  children?: React.ReactNode;
  showBackButton?: boolean;
  step?: number;
  setStep?: (step: number) => void;
}

const ContentNavigation: FC<PropsWithChildren<IContentNavigation>> = ({
  children,
  showBackButton = true,
  step,
  setStep
}) => {
  const { step: stepServiceForm } = useAppStore(state => state);
  const setStepServiceForm = useAppStore(state => state.setStep);

  const stepToUse = step || stepServiceForm;
  const setStepToUse = setStep || setStepServiceForm;

  return (
    <div className="content-navigation">
      {showBackButton && stepToUse > 1 && (
        <div className="nav-left">
          <Button
            className="btn-dark"
            onClick={() => setStepToUse(stepToUse - 1)}
            hasLeftIcon
          >
            <Icon icon="arrow-left" />
            Zurück
          </Button>
        </div>
      )}
      {children && (
        <div className="nav-right">
          {children}
        </div>
      )}
    </div>
  );
};

export default ContentNavigation;
