import { map } from 'lodash';
import type { FC } from 'react';
import React from 'react';

import ProductItem from '../../../components/ProductItem';
import useAppStoreRent from '../../../service/store/appRent';
import useOrderStoreRent from '../../../service/store/orderRent';
import type { IRentalProduct } from '../../../types';

import './../../../components/ProductList.scss';

const ProductList: FC = () => {
  const addProduct = useOrderStoreRent(state => state.addProduct);
  const {
    filteredProducts
  } = useAppStoreRent(state => state);

  const handleProductCardClick = (productItem: IRentalProduct): void => {
    addProduct(productItem);
  };

  return (
    <div className="manufacturer-group-container">
      {filteredProducts && (
        <div className="product-list product-list-rent">
          {map(filteredProducts, productItem => (
            <ProductItem
              type="rent"
              key={productItem.id}
              name={productItem.name}
              image={productItem.image}
              btnText="Gerät mieten"
              onClick={() => handleProductCardClick(productItem)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductList;
