import classNames from 'classnames';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useEffect, useContext } from 'react';
import React from 'react';

import ConfigContext from '../service/context/config';
import ConfigVosContext from '../service/context/config-vos';
import type { IAddress } from '../types';
import { DEFAULT_DELIVERY_COUNTRY } from '../utils/constants';
import { isEmail } from '../utils/validate';

import Input from './ui/Input';
import './ui/Input.scss';
import Select from './ui/Select';

interface IAddressProps {
  deliveryCountries?: string[];
  address?: IAddress;
  showFax?: boolean;
  showCustomerNumber?: boolean;
  showCountrySelect?: boolean;
  customerNumber?: string;
  showReferenceNumber?: boolean;
  showError?: boolean;
  referenceNumber?: string;
  propertyKey: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const Address: FC<IAddressProps> = ({
  propertyKey,
  customerNumber = '',
  showCustomerNumber = false,
  showCountrySelect = false,
  referenceNumber = '',
  showReferenceNumber = false,
  showFax = false,
  showError = false,
  deliveryCountries,
  address,
  onChange
}) => {
  const appContext = useContext(ConfigContext);
  const appContextVos = useContext(ConfigVosContext);
  const {
    delivery_countries: deliveryCountriesServiceForm,
    delivery_countries_mapping: countryMapping
  } = appContext || appContextVos || {};
  const {
    country,
    firstname,
    lastname,
    company,
    email,
    street,
    house_number,
    salutation,
    zip,
    city,
    phone,
    fax
  } = address || {};
  const deliverCountriesToUse = useMemo(() => {
    return deliveryCountries || deliveryCountriesServiceForm || [ DEFAULT_DELIVERY_COUNTRY ];
  }, [ deliveryCountries, deliveryCountriesServiceForm ]);

  useEffect(() => {
    if (onChange && showCountrySelect && !country) {
      onChange({
        target: {
          value: deliverCountriesToUse[0],
          name: `${propertyKey}.country`
        } as unknown as EventTarget
      } as unknown as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
    }
  }, [ country, deliverCountriesToUse, onChange, propertyKey, showCountrySelect ]);

  useEffect(() => {
    if (onChange && countryMapping && country && countryMapping[country]) {
      onChange({
        target: {
          value: countryMapping[country],
          name: `${propertyKey}.country_code`
        } as unknown as EventTarget
      } as unknown as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>);
    }
  }, [ country, countryMapping, onChange, propertyKey ]);

  return (
    <div className="form-grid">
      <div>
        {showCustomerNumber && (
          <Input
            label="Kundenummer (falls vorhanden)"
            value={customerNumber}
            name="customer_number"
            onChange={onChange}
          />
        )}
        <Input
          label="Firmenname"
          value={company}
          name={`${propertyKey}.company`}
          onChange={onChange}
        />
        <div className="styled-input">
          <label>Ansprechpartner Vor- & Nachname</label>
          <div className="styled-input-wrap">
            <Select
              options={[ '', 'Herr', 'Frau' ]}
              value={salutation}
              name={`${propertyKey}.salutation`}
              onChange={onChange}
              wrapperClass={classNames('max-w', {
                'has-error': showError && !salutation
              })}
              required
            />
            <Input
              value={firstname}
              name={`${propertyKey}.firstname`}
              onChange={onChange}
              wrapperClass={classNames({
                'has-error': showError && !firstname
              })}
            />
            <Input
              value={lastname}
              name={`${propertyKey}.lastname`}
              onChange={onChange}
              wrapperClass={classNames({
                'has-error': showError && !lastname
              })}
            />
          </div>
        </div>
        <Input
          type="email"
          label="E-Mail"
          value={email}
          name={`${propertyKey}.email`}
          onChange={onChange}
          wrapperClass={classNames({
            'has-error': showError && (!email || !isEmail(email))
          })}
        />
      </div>
      <div>
        {showCountrySelect && deliverCountriesToUse.length && (
          <Select
            options={deliverCountriesToUse}
            label="Land"
            value={country}
            name={`${propertyKey}.country`}
            onChange={onChange}
            wrapperClass={classNames({
              'has-error': showError && !country
            })}
          />
        )}
        <div className="styled-input">
          <label>Straße & Hausnummer</label>
          <div className="styled-input-wrap">
            <Input
              value={street}
              name={`${propertyKey}.street`}
              onChange={onChange}
              wrapperClass={classNames({
                'has-error': showError && !street
              })}
            />
            <Input
              value={house_number}
              name={`${propertyKey}.house_number`}
              onChange={onChange}
              wrapperClass={classNames({
                'has-error': showError && !house_number
              })}
              isSmall
            />
          </div>
        </div>
        <div className="styled-input">
          <label>Postleitzahl & Ort</label>
          <div className="styled-input-wrap">
            <Input
              value={zip}
              name={`${propertyKey}.zip`}
              onChange={onChange}
              wrapperClass={classNames({
                'has-error': showError && !zip
              })}
              isSmall
            />
            <Input
              value={city}
              name={`${propertyKey}.city`}
              onChange={onChange}
              wrapperClass={classNames({
                'has-error': showError && !city
              })}
            />
          </div>
        </div>
        {showReferenceNumber && (
          <Input
            label="Referenznummer"
            value={referenceNumber}
            name="reference_number"
            onChange={onChange}
          />
        )}
        <Input
          type="phone"
          label="(Mobil-) Rufnummer"
          value={phone}
          name={`${propertyKey}.phone`}
          onChange={onChange}
          wrapperClass={classNames({
            'has-error': showError && !phone
          })}
        />
        {showFax && (
          <Input
            type="phone"
            label="FAX"
            value={fax}
            name={`${propertyKey}.fax`}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
};

export default Address;
