import type { FC } from 'react';

import './DateIcon.scss';

interface IDateIcon {
  month: string;
  day: string;
}

const DateIcon: FC<IDateIcon> = ({
  month,
  day
}) => {
  return (
    <div className="date-icon">
      <div className="month">{month}</div>
      <div className="day">{day}</div>
    </div>
  );
};

export default DateIcon;
