import { map } from 'lodash';
import type { FC } from 'react';
import React from 'react';

import Input from '../components/ui/Input';
import type { AppConfig, AppConfigVos } from '../types';

interface IProductAccessoriesProps {
  accessories: { [key: string]: number } | undefined;
  setAccessories: (accessories: { [key: string]: number }) => void;
  config?: AppConfig | AppConfigVos | null;
}

const ProductAccessories: FC<IProductAccessoriesProps> = ({
  accessories,
  setAccessories,
  config
}) => {
  // const {
  //   accessories
  // } = useOrderStore(state => state.order) || {};
  // const setAccessories = useOrderStore(state => state.setAccessories);
  // const config = useContext(ConfigContext);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { name, value } = event.target;
    const parsedValue = parseInt(value, 10);
    const updatedProductData = { ...accessories };

    if (parsedValue && parsedValue > 0) {
      updatedProductData[name] = parsedValue;
    } else {
      delete updatedProductData[name];
    }

    setAccessories(updatedProductData);
  };

  return (
    <div className="input-container input-container-accessories">
      {map(config?.accessories, accessory => (
        <Input
          key={accessory.key}
          type="number"
          label={accessory.name}
          name={accessory.key}
          value={accessories?.[accessory.key]}
          onChange={handleInputChange}
          inline
        />
      ))}
    </div>
  );
};

export default ProductAccessories;
