import type { FC } from 'react';
import React from 'react';

import useAppStore from '../service/store/app';

import './ProductInfoCard.scss';
import Icon from './ui/Icon';
import Image from './ui/Image';

const ProductInfoCard: FC = () => {
  const selectedProduct = useAppStore(state => state.selectedProduct);

  if (!selectedProduct) {
    return (
      <div className="product-info-card">
        Nur Zubehör
      </div>
    );
  }

  const { name, image } = selectedProduct;
  const isUnknown = name.toLowerCase().endsWith('unbekannt');

  return (
    <div className="product-info-card">
      <div className="product-image">
        {isUnknown ? (
          <Icon icon="question" />
        ) : (
          <Image
            url={image}
            alt={name}
            title={name}
            className="product-item-image"
          />
        )}
      </div>
      {name}
    </div>
  );
};

export default ProductInfoCard;
