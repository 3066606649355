export const headlineContentVos = [
  {
    title: 'Einen Fachhandel auswählen',
    lead: 'Bitte wählen Sie einen Fachhandel aus, bei dem Sie einen Vor-Ort-Service beanspruchen wollen.'
  },
  {
    title: 'Von Welchem Hersteller stammt ihr Gerät?',
    // title: 'Welches Gerät Wollen Sie Reparieren Lassen?',
    lead: 'Bitte wählen Sie aus, für welches Gerät Sie eine Inspektion beauftragen möchten.',
    // lead: 'Bitte wählen Sie aus, für welches Gerät Sie eine Inspektion beauftragen möchten.',
    back: 'Zurück zu Fachhandel auswahl'
  },
  {
    title: 'Wählen Sie ihr zubehör',
    lead: 'Bitte wählen Sie aus, welches Zubehör Sie ihrem Service beilegen wollen.',
    back: 'Geräteauswahl'
  },
  {
    title: 'Ihre Daten',
    lead: 'Bitte wählen Sie aus, für welches Gerät Sie eine Reparatur beauftragen möchten.',
    back: 'Geräteauswahl'
  },
  {
    title: 'Beauftragung Abgeschlossen',
    lead: 'Vielen Dank für Ihr Vertrauen in unseren Service.',
    back: 'Einen Service Beauftragen'
  }
];

export const MAX_STEPS_VOS = 5;
export const APPOINTMENT_DATE_FALLBACK_COUNT = 3;
