import { TOOL_SERVICE_KEY, TOOL_SERVICE_KEY_EXTENSION, TOOL_SERVICE_NAME, TOOL_SERVICE_SMALL_IMAGE } from './constants';

export function getServiceProvider(): {
  name: string;
  key: string;
  small_image: string;
  extension: string;
} {
  return {
    name: TOOL_SERVICE_NAME,
    key: TOOL_SERVICE_KEY,
    small_image: TOOL_SERVICE_SMALL_IMAGE,
    extension: TOOL_SERVICE_KEY_EXTENSION
  };
}
