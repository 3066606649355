import { create } from 'zustand';

import type { Product } from '../../types';

interface IAppStoreStateVos {
  step: number;
  filter: {
    date: string;
    search: string;
    customer: string;
    city: string;
  };
  resetAppState: () => void;
  setFilter: (propertyKey: string, value: string) => void;
  selectedProduct?: Product;
  setSelectedProduct: (product: Product | undefined) => void;
  increase: (by: number) => void;
  decrease: (by: number) => void;
  setStep: (step: number) => void;
}

const initialState = {
  step: 1,
  filter: {
    date: '',
    search: '',
    customer: '',
    city: ''
  },
  selectedProduct: undefined
};

const useAppStoreVos = create<IAppStoreStateVos>(set => ({
  ...initialState,
  resetAppState: () => {
    set(initialState);
  },
  setFilter: (propertyKey: string, value: string) => set(state => ({
    filter: {
      ...state.filter,
      [propertyKey]: value
    }
  })),
  setSelectedProduct: (product: Product | undefined) => set({ selectedProduct: product }),
  increase: (by: number) => set(state => ({ step: state.step + by })),
  decrease: (by: number) => set(state => ({ step: state.step - by })),
  setStep: (step: number) => set(() => {
    let stepToSet;

    // if (
    //   // No product selected -> jump to product select
    //   step === 3
    //   && !state.selectedProduct
    // ) {
    //   stepToSet = 2;
    // } else {
    //   stepToSet = step;
    // }

    // eslint-disable-next-line prefer-const
    stepToSet = step;

    return {
      step: stepToSet
    };
  })
}));

export default useAppStoreVos;
