import { capitalize } from 'lodash';
import type { FC } from 'react';
import React from 'react';

import ContentNavigation from '../components/ContentNavigation';
import Filter from '../components/Filter';
import ProductList from '../components/ProductList';
import Button from '../components/ui/Button';
import Icon from '../components/ui/Icon';
import useAppStore from '../service/store/app';
import useOrderStore from '../service/store/order';
import { UNKNOWN } from '../utils/constants';
import { scrollToRef } from '../utils/scroll';

const StepProductSelect: FC = () => {
  const {
    setSelectedProduct,
    filter: { manufacturer }
  } = useAppStore(state => state);
  const setProduct = useOrderStore(state => state.setProduct);
  const setStep = useAppStore(state => state.setStep);

  const handleUnknownProductClick = (): void => {
    setProduct(UNKNOWN, capitalize(UNKNOWN));
    setSelectedProduct({
      color: '',
      image: '',
      manufacturer: '',
      manufacturer_key: '',
      price: '54.90',
      price_string: '',
      row: 0,
      service_provider: '',
      service_provider_key: '',
      type_of_drive: '',
      name: capitalize(UNKNOWN),
      id: UNKNOWN
    });
    setStep(3);
    scrollToRef();
  };

  const handleOnlyAccessoriesClick = (): void => {
    setProduct(0, 'nur Zubehör');
    setSelectedProduct(undefined);
    setStep(4);
    scrollToRef();
  };

  return (
    <>
      <ContentNavigation>
        <>
          {!!manufacturer && (
            <Button
              className="btn-dark"
              onClick={handleUnknownProductClick}
              icon="question"
              hasLeftIcon
            >
              Ich finde mein Gerät nicht
            </Button>
          )}
          <Button
            className="btn-dark"
            onClick={handleOnlyAccessoriesClick}
            hasRightIcon
          >
            Ich habe nur Zubehör
          </Button>
        </>
      </ContentNavigation>
      <div className="box">
        <div className="box-content">
          <Filter />
          <ProductList />
        </div>
      </div>
      <div className="under-box">
        {!!manufacturer && (
          <div
            className="unkown-link"
            onClick={handleUnknownProductClick}
          >
            <Icon icon="question" /> Ich finde mein Gerät nicht
          </div>
        )}
        <div className="under-box-right">
          <Button
            className="btn-dark-desktop btn-l btn-full"
            onClick={handleOnlyAccessoriesClick}
            hasRightIcon
          >
            Ich habe nur Zubehör
          </Button>
        </div>
      </div>
    </>
  );
};

export default StepProductSelect;
