import classNames from 'classnames';
import type { FC } from 'react';
import React, { useState } from 'react';

import './Input.scss';

interface IInputNumberProps {
  id?: string;
  name?: string;
  value?: number;
  min?: number;
  required?: boolean;
  useSmall?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const InputNumber: FC<IInputNumberProps> = ({
  id,
  value: propValue,
  useSmall = false,
  onChange,
  min = 0,
  name,
  required
}) => {
  const [ value, setValue ] = useState(propValue || min);

  const triggerChange = (newValue: number): void => {
    if (onChange) {
      onChange({
        target: {
          value: newValue,
          name: name || ''
        }
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleButtonClick = (isIncrement: boolean): void => {
    const newValue = isIncrement ? value + 1 : value - 1;

    if (newValue >= min) {
      setValue(newValue);
      triggerChange(newValue);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = parseInt(event.target.value, 10) || min;
    const valueToUse = newValue >= min ? newValue : min;

    setValue(valueToUse);
    triggerChange(valueToUse);
  };

  return (
    <div className={classNames('input-number-wrap', {
      'input-number-small': useSmall
    })}>
      <button
        className="btn-down"
        onClick={() => handleButtonClick(false)}
      >
        -
      </button>
      <input
        type="number"
        value={value}
        onChange={handleInputChange}
        id={id}
        name={name}
        required={required}
        min={min}
      />
      <button
        className="btn-up"
        onClick={() => handleButtonClick(true)}
      >
        +
      </button>
    </div>
  );
};

export default InputNumber;
