import classNames from 'classnames';
import { size } from 'lodash';
import type { FC } from 'react';
import React, { useState, useEffect } from 'react';

import Button from '../../components/ui/Button';
import useAppStoreRent from '../../service/store/appRent';
import useOrderStoreRent from '../../service/store/orderRent';
import { scrollToRef } from '../../utils/scroll';
import { validateOrderRent } from '../../utils/validate';

import './../../components/ui/Button.scss';
import CartDelivery from './components/CartDelivery';
import CartProducts from './components/CartProducts';

interface IRentCartProps {
  placeOrder?: () => Promise<void>;
}

const RentCart: FC<IRentCartProps> = ({
  placeOrder
}) => {
  const [ tab, setTab ] = useState(0);
  const { step } = useAppStoreRent(state => state);
  const {
    order
  } = useOrderStoreRent(state => state) || {};
  const { products } = order;
  const validOrder = validateOrderRent(order);
  const {
    setShowErrors,
    setStep
  } = useAppStoreRent(state => state);
  const isValid = size(products) > 0;

  const nextStep = (): void => {
    setStep(2);
    scrollToRef();
  };

  useEffect(() => {
    if (step === 1) {
      setTab(0);
    } else if (step === 2) {
      setTab(1);
    }
  }, [ step ]);

  const handleCtaClick = (): void => {
    if (validOrder && placeOrder) {
      void placeOrder();
      scrollToRef();
    } else {
      setShowErrors(true);
      scrollToRef();
      // errorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <div className="box has-no-shadow">
        <div className="box-content">
          <div className="tab-navi-wrap">
            <div className="tab-navi">
              <button
                className={classNames('btn', {
                  inactive: tab !== 0
                })}
                onClick={() => setTab(0)}
              >
                Geräte
              </button>
              <button
                className={classNames('btn', {
                  inactive: tab !== 1
                })}
                onClick={() => setTab(1)}
              >
                Versand
              </button>
            </div>
          </div>
          {tab === 0 && (
            <CartProducts />
          )}
          {tab === 1 && (
            <CartDelivery />
          )}
        </div>
      </div>
      <div className="box-hint">
        <div>
          Alle Preise zzgl. Mehrwertsteuer
        </div>
        <div className="box-hint-logo">
          Powered by <img src="./toolservice-logo-light.png" alt="Tool Service Logo" height="20px" />
        </div>
      </div>
      <div className="cart-cta">
        {step === 1 && (
          <Button
            disabled={!isValid}
            className={classNames('btn-xl btn-full-mobile', {
              'btn-disabled': !isValid
            })}
            // className="btn-l btn-full"
            onClick={() => nextStep()}
            hasRightIcon
          >
            Weiter zum Versand
          </Button>
        )}
        {step === 2 && (
          <>
            <Button
              className={classNames('btn-xl btn-full-mobile', {
                'btn-disabled': !validOrder
              })}
              onClick={handleCtaClick}
              hasRightIcon
              icon="arrow-right"
              // disabled={!validOrder}
            >
              Jetzt Miete beauftragen
            </Button>
            <div className="box-legal">
              Mit deiner Bestellung erklärst du dich mit unseren {/* */}
              <a href="https://www.toolservice.de/agb" target="_top" rel="noreferrer">
                AGB
              </a> und {/* */}
              <a href="https://www.toolservice.de/datenschutz" target="_top" rel="noreferrer">
                Datenschutzbestimmungen
              </a> einverstanden.
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RentCart;
