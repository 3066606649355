import { filter, isEmpty, sortBy, take } from 'lodash';

import { APPOINTMENT_DATE_FALLBACK_COUNT } from '../forms/vos/constants';
import type { IAppointment } from '../types';

export function findClosestDates(dataArray?: IAppointment[], inputDate?: string): undefined | IAppointment[] {
  if (!dataArray || !inputDate) {
    return undefined;
  }

  const dateToDays = (dateString: string): number => {
    const [ day, month, year ] = dateString.split('.');

    return Math.floor(
      new Date(Number(year), Number(month) - 1, Number(day)).getTime() / (1000 * 60 * 60 * 24)
    );
  };

  const targetDateDays = dateToDays(inputDate);
  const dataWithDateDiff = dataArray.map(item => ({
    ...item,
    dateDifference: Math.abs(dateToDays(item.date) - targetDateDays)
  }));

  const directMatches = filter(dataWithDateDiff, { dateDifference: 0 });

  if (!isEmpty(directMatches)) {
    return directMatches;
  }

  return take(sortBy(dataWithDateDiff, [ 'dateDifference' ]), APPOINTMENT_DATE_FALLBACK_COUNT);
}
