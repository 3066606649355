import type { FC } from 'react';
import React from 'react';

export interface IImageProps {
  url: string;
  alt: string;
  title?: string;
  type?: 'products' | 'manufacturer' | 'services' | 'service_provider' | 'content' | 'rent';
  className?: string;
}

const Image: FC<IImageProps> = ({
  url,
  alt,
  title,
  type = 'products',
  className
}) => {
  return (
    <img
      src={`${process.env.REACT_APP_API_URL}/assets/${type}/${url}`}
      alt={alt}
      title={title}
      className={className}
    />
  );
};

export default Image;
