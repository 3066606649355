import { uniq } from 'lodash';
import { create } from 'zustand';

import type { Product } from '../../types';

interface IAppStorState {
  step: number;
  filter: {
    search: string;
    manufacturer: string;
    type: string;
    color: string;
  };
  availableFilter: {
    type: string[];
    color: string[];
  };
  products: Product[];
  filteredProducts: Product[];
  batteriesAlertSeen?: boolean;
  resetAppState: () => void;
  setProducts: (products: Product[]) => void;
  setFilteredProducts: (products: Product[]) => void;
  setFilter: (propertyKey: string, value: string) => void;
  setFilterAdvanced: (propertyKey: string, value: string) => void;
  setAvailableFilter: (availableFilter: IAppStorState['availableFilter']) => void;
  selectedProduct?: Product;
  setSelectedProduct: (product: Product | undefined) => void;
  increase: (by: number) => void;
  decrease: (by: number) => void;
  setStep: (step: number) => void;
  setBatteriesAlertSeen: (seen: boolean) => void;
}

const initialState = {
  step: 1,
  products: [],
  filteredProducts: [],
  filter: {
    search: '',
    manufacturer: '',
    type: '',
    color: ''
  },
  availableFilter: {
    type: [],
    color: []
  },
  selectedProduct: undefined
};

const useAppStore = create<IAppStorState>(set => ({
  ...initialState,
  resetAppState: () => {
    set(initialState);
  },
  setFilter: (propertyKey: string, value: string) => set(state => {
    if (
      propertyKey === 'manufacturer'
      && value === ''
    ) {
      return {
        filter: {
          ...state.filter,
          [propertyKey]: value,
          type: '',
          color: ''
        }
      };
    }

    return {
      filter: {
        ...state.filter,
        [propertyKey]: value
      }
    };
  }),
  setProducts: (products: Product[]) => set({ products }),
  setFilteredProducts: (filteredProducts: Product[]) => set({ filteredProducts }),
  setFilterAdvanced: (propertyKey: string, value: string) => {
    set(state => {
      const filter = propertyKey === 'reset' ? {
        manufacturer: '',
        search: '',
        type: '',
        color: ''
      } : {
        ...state.filter,
        [propertyKey]: value
      };

      if (propertyKey === 'manufacturer') {
        // filter need to be reset when manufacterer is set
        filter.search = '';
        filter.type = '';
        filter.color = '';
      }

      const {
        search,
        type,
        color,
        manufacturer
      } = filter;

      const filteredProducts = state.products?.filter(productItem => {
        if (
          !!search
          && (
            !productItem.name.toLowerCase().includes(search.toLowerCase())
            && !productItem.id.includes(search)
          )
        ) {
          return false;
        }

        if (!!color && !productItem.color.includes(color)) {
          return false;
        }

        if (!!type && productItem.type_of_drive !== type) {
          return false;
        }

        if (!!manufacturer && !productItem.manufacturer.includes(manufacturer)) {
          return false;
        }

        return true;
      });

      const tmpAvailableFilter: {
        type: string[];
        color: string[];
      } = {
        type: [ '' ],
        color: [ '' ]
      };

      filteredProducts?.forEach(productItem => {
        tmpAvailableFilter.color.push(
          productItem.color
        );

        tmpAvailableFilter.type.push(productItem.type_of_drive);
      });

      tmpAvailableFilter.color = uniq(tmpAvailableFilter.color).sort();
      tmpAvailableFilter.type = uniq(tmpAvailableFilter.type).sort();

      return {
        filter,
        availableFilter: tmpAvailableFilter,
        filteredProducts
      };
    });
  },
  setBatteriesAlertSeen: (seen: boolean) => set({ batteriesAlertSeen: seen }),
  setSelectedProduct: (product: Product | undefined) => set({ selectedProduct: product }),
  setAvailableFilter: (availableFilter: IAppStorState['availableFilter']) => set({ availableFilter }),
  increase: (by: number) => set(state => ({ step: state.step + by })),
  decrease: (by: number) => set(state => ({ step: state.step - by })),
  setStep: (step: number) => set(state => {
    /*
    {step === 1 && (
      <StepServiceSelect />
    )}
    {step === 2 && (
      <StepProductSelect />
    )}
    {step === 3 && (
      <StepProductInfo />
    )}
    {step === 4 && (
      <StepAccessoriesSelect />
    )}
    {step === 5 && (
      <StepCustomerData onClick={placeOrder} />
    )}
    {step === 6 && (
      <StepConfirmation />
    )}
    */
    let stepToSet;

    if (
      // No product selected -> jump to product select
      step === 3
      && !state.selectedProduct
    ) {
      stepToSet = 2;
    } else {
      stepToSet = step;
    }

    return {
      step: stepToSet
    };
  })
}));

export default useAppStore;
