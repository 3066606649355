import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import './Input.scss';

interface ISelectProps {
  label?: string;
  name?: string;
  value?: string | number;
  wrapperClass?: string;
  options: string[];
  isSmall?: boolean;
  inline?: boolean;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const Select: FC<ISelectProps> = ({
  label,
  value,
  name,
  onChange,
  required,
  isSmall,
  options,
  inline,
  wrapperClass
}) => {
  return (
    <div className={classNames('styled-input', {
      'size-xs': isSmall,
      inline
    }, wrapperClass)}>
      {label && (<label htmlFor={name}>{label}</label>)}
      <select
        name={name}
        value={value}
        onChange={onChange}
        required={required}
      >
        {options.map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
};

export default Select;
