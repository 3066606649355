export const isWeekday = (date: Date): boolean => {
  const day = date.getDay();

  return day !== 0 && day !== 6;
};

export const findNextWeekday = (startDate: Date): Date => {
  const nextDate = new Date(startDate);

  nextDate.setDate(nextDate.getDate() + 1);

  while (!isWeekday(nextDate)) {
    nextDate.setDate(nextDate.getDate() + 1);
  }

  return nextDate;
};
