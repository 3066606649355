import type { FC } from 'react';
import React from 'react';

import { getServiceProvider } from '../utils/service_provider';

import './ServiceProviderLogo.scss';
import Image from './ui/Image';

interface IServiceProviderLogoProps {
  useSmall?: boolean;
}

const ServiceProviderLogo: FC<IServiceProviderLogoProps> = ({
  useSmall = false
}) => {
  const { name, key, extension, small_image } = getServiceProvider();
  const useSmallImage = useSmall && small_image;
  const imageToUse = useSmallImage ? small_image : `${key}.${extension}`;

  return (
    <div className="service-provider-logo">
      <Image
        type="service_provider"
        url={imageToUse}
        alt={name}
      />
    </div>
  );
};

export default ServiceProviderLogo;
