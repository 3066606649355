import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import type { IAddress } from '../types';

import Address from './Address';
import './ui/Input.scss';

interface IAddressToggleProps {
  title?: string;
  address?: IAddress;
  showAddress?: boolean;
  showError?: boolean;
  propertyKey: string;
  toggleFunction: (propertyKey: string, visible: boolean) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const AddressToggle: FC<IAddressToggleProps> = ({
  title,
  propertyKey,
  address,
  toggleFunction,
  showAddress = false,
  showError = false,
  onChange
}) => {
  return (
    <div>
      <div className="styled-input">
        {title && (<label>{title}</label>)}
        <div className="buttons-wrap">
          <button
            className={classNames({ active: showAddress })}
            onClick={() => toggleFunction(propertyKey, true)}
          >
            Ja
          </button>
          <button
            className={classNames({ active: !showAddress })}
            onClick={() => toggleFunction(propertyKey, false)}
          >
            Nein
          </button>
        </div>
      </div>
      {showAddress && (
        <Address
          showError={showError}
          propertyKey={propertyKey}
          address={address}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default AddressToggle;
