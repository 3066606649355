import type { FC } from 'react';
import React from 'react';

import CartPortal from '../../../components/CartPortal';
import ContentNavigation from '../../../components/ContentNavigation';
import Button from '../../../components/ui/Button';
import useAppStoreVos from '../../../service/store/appVos';
import ManufacturerList from '../components/ManufacturerList';

const StepSelectManufacturer: FC = () => {
  const { step, setStep } = useAppStoreVos(state => state);

  return (
    <>
      <ContentNavigation
        step={step}
        setStep={setStep}
      />
      <div className="box">
        <div className="box-header">
          Bitte wähle die Anzahl deiner Geräte
          <div className="box-header-right">
            <Button
              onClick={() => setStep(3)}
              hasRightIcon
              icon="arrow-right"
            >
              Fortfahren
            </Button>
          </div>
          <CartPortal>
            <Button
              onClick={() => setStep(3)}
              hasRightIcon
              icon="arrow-right"
            >
              Fortfahren
            </Button>
          </CartPortal>
        </div>
        <div className="box-content">
          <ManufacturerList />
        </div>
      </div>
    </>
  );
};

export default StepSelectManufacturer;
