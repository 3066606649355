import { map } from 'lodash';
import type { FC } from 'react';
import React from 'react';

import Input from '../components/ui/Input';
import type { AppConfig, AppConfigVos } from '../types';
import { type Order } from '../types';

interface IProductJawsProps {
  jaws: { [key: string]: number } | undefined;
  setJaws: (jaws: Order['jaws']) => void;
  config?: AppConfig | AppConfigVos | null;
}

const ProductJaws: FC<IProductJawsProps> = ({
  jaws,
  setJaws,
  config
}) => {
  // const {
  //   jaws
  // } = useOrderStore(state => state.order) || {};
  // const setJaws = useOrderStore(state => state.setJaws);
  // const config = useContext(ConfigContext);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    const { name, value } = event.target;
    const parsedValue = parseInt(value, 10);
    const updatedProductData = { ...jaws };

    if (parsedValue && parsedValue > 0) {
      updatedProductData[name] = parsedValue;
    } else {
      delete updatedProductData[name];
    }

    setJaws(updatedProductData);
  };

  return (
    <div className="input-container">
      {map(config?.jaws, accessory => (
        <Input
          key={accessory.key}
          type="number"
          label={accessory.name}
          name={accessory.key}
          value={jaws?.[accessory.key]}
          onChange={handleInputChange}
          inline
        />
      ))}
    </div>
  );
};

export default ProductJaws;
