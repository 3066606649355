import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import { getServiceProvider } from '../../utils/service_provider';
import ServiceProviderLogo from '../ServiceProviderLogo';

import Button from './Button';
import Icon from './Icon';
import './Progressbar.scss';

interface IProgressbarProps {
  reset: () => void;
  resetButtonText?: string;
}

const Progressbar: FC<IProgressbarProps> = ({
  reset,
  resetButtonText = 'Einen weitere Abholung beauftragen'
}) => {
  const { name: serviceProviderName } = getServiceProvider();

  const [ progress, setProgress ] = useState(0);
  const [ isDone, setIsDone ] = useState(false);

  useEffect(() => {
    const steps: number[] = [ 0, 15, 25, 40, 65, 90, 100 ];

    let stepIndex = 0;
    const interval = setInterval(() => {
      if (stepIndex <= steps.length) {
        setIsDone(false);
        setProgress(steps[stepIndex]);

        if (stepIndex === steps.length) {
          setIsDone(true);
        }

        stepIndex++;
      } else {
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="order-confirmation-container">
      <div className="progressbar-wrapper">
        <CircularProgressbar
          value={progress}
          text={undefined}
          styles={buildStyles({
            strokeLinecap: 'butt'
          })}
        />
        <div className="progressbar-overlay">
          {isDone ? (
            <Icon icon="checkmark" size="32" />
          ) : (
            <ServiceProviderLogo useSmall />
          )}
        </div>
      </div>
      {isDone ? (
        <>
          <div className="confirm-content">
            <p>
              <strong>Vielen Dank! Ihre Anfrage ist eingegangen und wird bearbeitet.</strong>
            </p>
            <p className="confirm-info">
              Sie erhalten zusätzlich noch eine Bestätigungsmail mit allen Auftragsdetails.<br />
              Bei Rückfragen melden wir uns über die Angegebenen Kontaktdaten.
            </p>
          </div>
          <Button
            className="btn--ghost"
            onClick={reset}
          >
            {resetButtonText}
          </Button>
        </>
      ) : (
        <p>
          <strong>
            Einen Moment bitte.<br />
            Ihre Anfrage wird an {serviceProviderName} übermittelt.
          </strong>
        </p>
      )}
    </div>
  );
};

export default Progressbar;
