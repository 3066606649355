import { produce } from 'immer';
import { set as lSet } from 'lodash';
import { create } from 'zustand';

import type { IAppointment, IDealer, OrderVos } from '../../types';

interface IOrderStateVos {
  nonce?: string;
  order?: OrderVos;
  resetOrderState: () => void;
  setNonce: (nonce: string) => void;
  setValue: (value: string | boolean | number, key: string) => void;
  setAccessories: (accessories: OrderVos['accessories']) => void;
  setJaws: (jaws: OrderVos['jaws']) => void;
  setManufacturer: (manufacturer: OrderVos['manufacturer']) => void;
  setDealer: (dealer: IDealer | undefined) => void;
  setAppointment: (appointment: IAppointment | undefined) => void;
}

const initialState = {
  order: {
    // extra setter
    manufacturer: {},
    dealer: undefined,
    appointment: undefined,
    checkedLegal: false,
    jaws: {
      jaws_12_35_mm: 0,
      jaws_42_54_mm: 0,
      jaws_64_mm: 0
    },
    accessories: {
      charger: 0,
      case: 0,
      mains_adapter: 0,
      batteries: 0
    },
    // generic setter
    customer_number: '',
    // address setter
    address: undefined
  }
};

const useOrderStoreVos = create<IOrderStateVos>(set => ({
  ...initialState,
  resetOrderState: () => {
    set(initialState);
  },
  // setters
  setNonce: nonce =>
    set(
      produce(draft => {
        draft.nonce = nonce;
      })
    ),
  setValue: (value, key) =>
    set(
      produce(draft => {
        lSet(draft.order, key, value);
      })
    ),
  setDealer: dealer =>
    set(
      produce(draft => {
        draft.order.dealer = dealer;
      })
    ),
  setAppointment: appointment =>
    set(
      produce(draft => {
        draft.order.appointment = appointment;
      })
    ),
  setAccessories: accessories =>
    set(
      produce(draft => {
        draft.order.accessories = accessories;
      })
    ),
  setManufacturer: manufacturer =>
    set(
      produce(draft => {
        draft.order.manufacturer = manufacturer;
      })
    ),
  setJaws: jaws =>
    set(
      produce(draft => {
        draft.order.jaws = jaws;
      })
    )
}));

export default useOrderStoreVos;
