export const headlineContentRent = [
  {
    title: 'Welche Geräte möchten Sie Mieten?',
    lead: 'Bitte wählen Sie aus, welche Geräte Sie mieten möchten.'
  },
  {
    title: 'Adresse & Versand',
    lead: 'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
    back: 'GeräteauswahL'
  },
  {
    title: 'Beauftragung Abgeschlossen',
    lead: 'Vielen Dank für Ihr Vertrauen in unseren Service.',
    back: 'Einen Service Beauftragen'
  }
];

export const MAX_STEPS_RENT = 3;
export const OPTION_PICKUP = 'Abholung';
export const OPTION_DELIVERY = 'Versand';
