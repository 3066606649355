import classNames from 'classnames';
import { capitalize, map } from 'lodash';
import type { FC } from 'react';
import React, { useContext } from 'react';

import { useManufacturer } from '../lib/manufacturer';
import ConfigContext from '../service/context/config';
import useAppStore from '../service/store/app';
import { UNKNOWN } from '../utils/constants';

import './Filter.scss';
import Icon from './ui/Icon';

const Filter: FC = () => {
  const appContext = useContext(ConfigContext);
  const {  manufacturers, hero_manufacturers } = appContext || {};
  // const filter = useAppStore(state => state.filter);
  const { search, manufacturer, type, color } = useAppStore(state => state.filter);
  // const setFilter = useAppStore(state => state.setFilter);
  const {
    setFilterAdvanced,
    availableFilter
  } = useAppStore(state => state);
  const {
    manufacturer: brands,
    topManufacturer: heroBrands
  } = useManufacturer(manufacturers, hero_manufacturers);

  return (
    <div className="filter-wrap">
      <div className="filter-item">
        <label>Marke/Hersteller</label>
        <select
          onChange={event => setFilterAdvanced('manufacturer', event.target.value)}
        >
          <option value="">bitte wählen</option>
          {heroBrands.length > 0 && (
            <>
              {map(heroBrands, manufacturerOption => (
                <option
                  key={manufacturerOption.key}
                  value={manufacturerOption.name}
                  selected={manufacturerOption.name === manufacturer}
                >
                  {manufacturerOption.name}
                </option>
              ))}
              <option value="">------</option>
            </>
          )}
          {map(brands, manufacturerOption => (
            <option
              key={manufacturerOption.key}
              value={manufacturerOption.name}
              selected={manufacturerOption.name === manufacturer}
            >
              {manufacturerOption.name}
            </option>
          ))}
          <option value={UNKNOWN}>{capitalize(UNKNOWN)}</option>
        </select>
      </div>
      <div className="filter-item">
        <label>Gerätebezeichnung</label>
        <div className={classNames('filter-item-serach', {
          disabled: !manufacturer
        })}>
          <Icon icon="search" />
          <input
            onChange={event => setFilterAdvanced('search', event.target.value)}
            type="text"
            value={search}
            placeholder="Suche"
            disabled={!manufacturer}
          />
        </div>
      </div>
      <div className={classNames('filter-item', {
        'filter-item-disabled': !manufacturer
      })}>
        <label>Antriebsart</label>
        <select
          onChange={event => setFilterAdvanced('type', event.target.value)}
          disabled={!manufacturer}
        >
          {map(availableFilter.type, typeOption => (
            <option
              key={typeOption}
              value={typeOption}
              selected={typeOption === type}
            >
              {typeOption}
            </option>
          ))}
        </select>
      </div>
      <div className={classNames('filter-item', {
        'filter-item-disabled': !manufacturer
      })}>
        <label>Farbe</label>
        <select
          onChange={event => setFilterAdvanced('color', event.target.value)}
          disabled={!manufacturer}
        >
          {map(availableFilter.color, colorOption => (
            <option
              key={colorOption}
              value={colorOption}
              selected={colorOption === color}
            >
              {colorOption}
            </option>
          ))}
        </select>
      </div>
      <div className="filter-reset">
        <button
          className={classNames('btn--reset', {
            disabled: !manufacturer
          })}
          onClick={() => setFilterAdvanced('reset', '')}
        >
          Filter zurücksetzen
        </button>
      </div>
    </div>
  );
};

export default Filter;
