import type { FC } from 'react';
import React from 'react';

import AppointmentList from '../components/AppointmentList';

interface IStepSelectAppointmentProps {
  placeReminder?: () => Promise<void>;
}

const StepSelectAppointment: FC<IStepSelectAppointmentProps> = ({
  placeReminder
}) => {
  return (
    <div className="box">
      <div className="box-content">
        <AppointmentList placeReminder={placeReminder} />
      </div>
    </div>
  );
};

export default StepSelectAppointment;
