import axios from 'axios';
import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import React from 'react';

import Headlines from '../../components/Headlines';
import ConfigRentContext from '../../service/context/config-rent';
import useAppStoreRent from '../../service/store/appRent';
import useOrderStoreRent from '../../service/store/orderRent';
import type { AppConfigRental, IDeliveryOption, IPickupOption, OrderApiResponse } from '../../types';

import StepConfirmation from './../vos/steps/StepConfirmation';
import { headlineContentRent, MAX_STEPS_RENT } from './constants';
import RentCart from './RentCart';
import StepProductSelect from './steps/ProductSelect';
import StepCustomerData from './steps/StepCustomerData';

const RentForm: FC = () => {
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ config, setConfig ] = useState<AppConfigRental | null>(null);
  const [ orderLoading, setOrderLoading ] = useState<boolean>(false);
  const [ orderSuccess, setOrderSuccess ] = useState<boolean>(false);
  const [ defaultDeliveryOption, setDefaultDeliveryOption ] = useState<IDeliveryOption | undefined>();
  const [ defaultPickupOption, setDefaultPickupOption ] = useState<IPickupOption | undefined>();
  const setStep = useAppStoreRent(state => state.setStep);
  const setProducts = useAppStoreRent(state => state.setProducts);
  const setFilteredProducts = useAppStoreRent(state => state.setFilteredProducts);
  const setAvailableFilter = useAppStoreRent(state => state.setAvailableFilter);
  const setOrderValue = useOrderStoreRent(state => state.setValue);

  const { order, resetOrderState } = useOrderStoreRent(state => state) || {};
  const { step, resetAppState } = useAppStoreRent(state => state);

  const resetApp = (): void => {
    // eslint-disable-next-line no-console
    console.log({ step, orderSuccess, orderLoading });

    if (
      step === MAX_STEPS_RENT
      && orderSuccess
      && !orderLoading
    ) {
      resetOrderState({
        ... defaultDeliveryOption && { delivery_option: defaultDeliveryOption },
        ... defaultPickupOption && { pickup_option: defaultPickupOption }
      });
      resetAppState();
      setOrderSuccess(false);
      setProducts(config?.products || []);
      setFilteredProducts(config?.products || []);
      setAvailableFilter({
        system: config?.system || [],
        type: config?.type || [],
        material: config?.material || [],
        manufacturer: config?.manufacturers || []
      });

      // eslint-disable-next-line no-console
      console.log('reset');
    }
  };

  const placeOrderHelper = async (endpoint = 'order-rent'): Promise<void> => {
    if (!orderLoading) {
      try {
        setOrderLoading(true);
        setStep(3);
        const { data } = await axios.post<OrderApiResponse>(`${process.env.REACT_APP_API_URL}/${endpoint}/`, {
          nonce: config?.nonce,
          order
        });

        if (data) {
          setOrderLoading(false);
          if (data.ok) {
            setOrderSuccess(true);
          }
        }
      } catch (error) {
        setOrderLoading(false);
      }
    }
  };

  const placeOrder = async (): Promise<void> => {
    void await placeOrderHelper();
  };

  useEffect(() => {
    const getData = async (): Promise<void> => {
      setLoading(true);
      const { data } = await axios.get<AppConfigRental>(
        `${process.env.REACT_APP_API_URL}/config-rent/`
      );

      setConfig(data);

      if (data.delivery_options && data.delivery_options.length) {
        setOrderValue(data.delivery_options[0], 'delivery_option');

        setDefaultDeliveryOption(data.delivery_options[0]);
      }

      if (data.pickup_options && data.pickup_options.length) {
        setOrderValue(data.pickup_options[0], 'pickup_option');

        setDefaultPickupOption(data.pickup_options[0]);
      }

      setLoading(false);
      setProducts(data.products);
      setFilteredProducts(data.products);
      setAvailableFilter({
        system: data.system,
        type: data.type,
        material: data.material,
        manufacturer: data.manufacturers
      });
    };

    void getData();
  }, [ setAvailableFilter, setFilteredProducts, setOrderValue, setProducts ]);

  if (loading) {
    return (
      <div>
        Loading...
      </div>
    );
  }

  return (
    <ConfigRentContext.Provider value={config}>
      <div className="app">
        <div className="container">
          <Headlines
            reset={resetApp}
            setStep={setStep}
            step={step}
            headlines={headlineContentRent}
            maxSteps={MAX_STEPS_RENT}
          />
        </div>
        <div className={classNames('container', {
          'has-content-navi': step > 1 && step < 3
        })}>
          <div className="left">
            {step === 1 && (
              <StepProductSelect />
            )}
            {step === 2 && (
              <StepCustomerData placeOrder={placeOrder} />
            )}
            {step === 3 && (
              <StepConfirmation
                reset={resetApp}
                resetButtonText="Weiteren Mietauftrag erstellen"
              />
            )}
          </div>
          <div className="right">
            <RentCart placeOrder={placeOrder} />
            <div id="cart-portal" className="cart-portal-rent"></div>
          </div>
        </div>
      </div>
    </ConfigRentContext.Provider>
  );
};

export default RentForm;
