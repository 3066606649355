import { createContext } from 'react';

import type { AppConfigRental } from '../../types';

export type TConfigRentContext = {
  config: AppConfigRental | null;
};

const ConfigRentContext = createContext<AppConfigRental | null>(null);

export default ConfigRentContext;
