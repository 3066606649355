import classNames from 'classnames';
import type { FC } from 'react';
import React from 'react';

import './Input.scss';
import InputNumber from './InputNumber';

interface IInputProps {
  label?: string;
  name?: string;
  placeholder?: string;
  wrapperClass?: string;
  value?: string | number;
  min?: number;
  isSmall?: boolean;
  inline?: boolean;
  type?: 'text' | 'number' | 'email' | 'phone' | 'date';
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

const Input: FC<IInputProps> = ({
  label,
  value,
  min = 0,
  placeholder,
  name,
  type = 'text',
  onChange,
  required,
  isSmall,
  inline,
  wrapperClass
}) => {
  return (
    <div className={classNames('styled-input', {
      'size-xs': isSmall,
      inline
    }, wrapperClass)}>
      {label && (<label htmlFor={name}>{label}</label>)}
      {type === 'number' ? (
        <InputNumber
          id={name}
          value={value as number}
          name={name}
          onChange={onChange}
          required={required}
          min={min}
        />
      ) : (
        <input
          id={name}
          type={type}
          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          // min={type === 'number' ? min : undefined}
        />
      )}
    </div>
  );
};

export default Input;
