import { map, size, sum, values } from 'lodash';
import type { FC } from 'react';
import React, { useContext } from 'react';

import ConfigContext from '../service/context/config';
import useOrderStore from '../service/store/order';
import { DEFAULT_DELIVERY_COUNTRY, JAWS_FREE_DELIVERY_COUNT } from '../utils/constants';
import { formatCurrency } from '../utils/format';

import './../Cart.scss';

const CartTransport: FC = () => {
  const appContext = useContext(ConfigContext);
  const { delivery_cost = 0, additional_delivery_cost_abroad = 0 } = appContext || {};
  const {
    jaws,
    number_of_parcels = 0,
    address: {
      country = DEFAULT_DELIVERY_COUNTRY
    } = {}
  } = useOrderStore(state => state.order) || {};
  const rows: string[] = [
    // 'Abholung',
    // 'Rückversand',
    // 'Expressversand'
  ];

  const sumJaws = sum(values(jaws));
  const useFreeDelivery = sumJaws >= JAWS_FREE_DELIVERY_COUNT;
  const additionalCostToUse = country === DEFAULT_DELIVERY_COUNTRY ? 0 : additional_delivery_cost_abroad;
  const deliveryCostToUse = delivery_cost + additionalCostToUse;
  const delverCost = formatCurrency(
    useFreeDelivery ? 0 : number_of_parcels * deliveryCostToUse
  );

  return (
    <div>
      <div className="cart-head">Transport</div>
      <div className="cart-row">
        <div className="cart-row-inner cart-row-inner-head">
          <div className="amount">Stk.</div>
          <div className="name">Zubehör</div>
          <div className="currency">Einzelpreis</div>
        </div>
      </div>
      <div className="cart-row">
        <div className="cart-row-inner">
          <div className="amount">
            {number_of_parcels} &times;
          </div>
          <div className="name">Abholung</div>
          <div className="currency">{delverCost}</div>
        </div>
        {useFreeDelivery && (
          <div className="cart-row-inner small">
            <div className="name">
              Wird durch Anzahl der Pressbacken ({sumJaws}) ersetzt.
            </div>
            <div className="currency free">Gratis</div>
          </div>
        )}
        {size(rows) > 0 ? map(rows, row => (
          <div key={row} className="cart-row-inner">
            <div className="amount">
              1 &times;
            </div>
            <div className="name">{row}</div>
            <div className="currency">{formatCurrency(0)}</div>
          </div>
        )) : null}
      </div>
    </div>
  );
};

export default CartTransport;
