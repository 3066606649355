import axios from 'axios';
import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import React from 'react';

import Headlines from '../../components/Headlines';
import ConfigVosContext from '../../service/context/config-vos';
import useAppStoreVos from '../../service/store/appVos';
import useOrderStoreVos from '../../service/store/orderVos';
import type { AppConfigVos, OrderApiResponse } from '../../types';

import { headlineContentVos, MAX_STEPS_VOS } from './constants';
import StepConfirmation from './steps/StepConfirmation';
import StepCustomerData from './steps/StepCustomerData';
import StepSelectAccessories from './steps/StepSelectAccessories';
import StepSelectAppointment from './steps/StepSelectAppointment';
import StepSelectManufacturer from './steps/StepSelectManufacturer';
import VosCart from './VosCart';

const VosForm: FC = () => {
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ config, setConfig ] = useState<AppConfigVos | null>(null);
  const [ orderLoading, setOrderLoading ] = useState<boolean>(false);
  const [ orderSuccess, setOrderSuccess ] = useState<boolean>(false);
  const setStep = useAppStoreVos(state => state.setStep);

  const { order, resetOrderState } = useOrderStoreVos(state => state) || {};
  const { step, resetAppState } = useAppStoreVos(state => state);

  const resetApp = (): void => {
    // eslint-disable-next-line no-console
    console.log({ step, orderSuccess, orderLoading });

    if (
      step === MAX_STEPS_VOS
      && orderSuccess
      && !orderLoading
    ) {
      resetOrderState();
      resetAppState();
      setOrderSuccess(false);

      // eslint-disable-next-line no-console
      console.log('reset');
    }
  };

  const placeOrderHelper = async (endpoint = 'order-vos'): Promise<void> => {
    if (!orderLoading) {
      try {
        setOrderLoading(true);
        setStep(5);
        const { data } = await axios.post<OrderApiResponse>(`${process.env.REACT_APP_API_URL}/${endpoint}/`, {
          nonce: config?.nonce,
          order
        });

        if (data) {
          setOrderLoading(false);
          if (data.ok) {
            setOrderSuccess(true);
          }
        }
      } catch (error) {
        setOrderLoading(false);
      }
    }
  };

  const placeOrder = async (): Promise<void> => {
    void await placeOrderHelper();
  };

  const placeReminder = async (): Promise<void> => {
    void await placeOrderHelper('order-vos-reminder');
  };

  useEffect(() => {
    const getData = async (): Promise<void> => {
      setLoading(true);
      const { data } = await axios.get<AppConfigVos>(
        `${process.env.REACT_APP_API_URL}/config-vos/`
      );

      setConfig(data);

      setLoading(false);
    };

    void getData();
  }, []);

  if (loading) {
    return (
      <div>
        Loading...
      </div>
    );
  }

  return (
    <ConfigVosContext.Provider value={config}>
      <div className="app">
        <div className="container">
          <Headlines
            reset={resetApp}
            setStep={setStep}
            step={step}
            headlines={headlineContentVos}
            maxSteps={MAX_STEPS_VOS}
          />
        </div>
        <div className={classNames('container', {
          'has-content-navi': step > 1 && step < 5
        })}>
          <div className="left">
            {step === 1 && (
              <StepSelectAppointment placeReminder={placeReminder} />
            )}
            {step === 2 && (
              <StepSelectManufacturer />
            )}
            {step === 3 && (
              <StepSelectAccessories />
            )}
            {step === 4 && (
              <StepCustomerData placeOrder={placeOrder} />
            )}
            {step === 5 && (
              <StepConfirmation
                reset={resetApp}
                resetButtonText="Weiteren Auftrag erstellen"
              />
            )}
          </div>
          <div className="right">
            <VosCart />
            <div id="cart-portal"></div>
          </div>
        </div>
      </div>
    </ConfigVosContext.Provider>
  );
};

export default VosForm;
