import { size, sumBy } from 'lodash';
import type { FC } from 'react';
import React from 'react';

import useOrderStoreRent from '../../../service/store/orderRent';
import { OPTION_DELIVERY, OPTION_PICKUP } from '../constants';

import { formatCurrency } from './../../../utils/format';
import './CartProducts.scss';

const CartDelivery: FC = () => {
  const {
    products,
    delivery_option,
    pickup_option,
    delivery_type
  } = useOrderStoreRent(state => state.order) || {};
  const totalQuantity = sumBy(Object.values(products), 'quantity');

  const isDelivery = delivery_type === OPTION_DELIVERY;
  const isPickup = delivery_type === OPTION_PICKUP;

  return (
    <div>
      <div className="cart-head-normal">Versandübersicht</div>
      <div className="cart-row">
        <div className="cart-row-inner cart-row-inner-head">
          <div className="amount">Stk.</div>
          {isPickup && (
            <div className="name">Abholung</div>
          )}
          {isDelivery && (
            <>
              <div className="name">Versandart</div>
              <div className="currency">Einzelpreis</div>
            </>
          )}
        </div>
      </div>
      {size(products) === 0 ? (
        <div className="cart-row">
          <em>Noch keine Produkte gewählt.</em>
        </div>
      ) : (
        <>
          {isPickup && pickup_option && (
            <div className="cart-row">
              <div className="cart-row-inner">
                <div className="amount">{totalQuantity} &times;</div>
                <div className="name">{pickup_option.name}</div>
              </div>
            </div>
          )}
          {isDelivery && delivery_option && (
            <div className="cart-row">
              <div className="cart-row-inner">
                <div className="amount">{totalQuantity} &times;</div>
                <div className="name">{delivery_option.name}</div>
                <div className="currency">{formatCurrency(delivery_option.cost)}</div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CartDelivery;
