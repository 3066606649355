import React, { forwardRef } from 'react';

import Button from './Button';
import Icon from './Icon';

interface IBoxModal {
  title?: string;
  btnText?: string;
  secondaryCtaText?: string;
  secondaryCtaButton?: string;
  btnIcon?: string;
  text: string;
  closeFunction: () => void;
  successFunction: () => void;
}

const BoxModal = forwardRef<HTMLDivElement, IBoxModal>(({
  closeFunction,
  successFunction,
  text,
  btnText = 'Verstanden',
  secondaryCtaText,
  secondaryCtaButton,
  btnIcon,
  title = 'Achtung'
}, ref) => {
  return (
    <div className="box-alert" ref={ref}>
      <div className="box-alert-inner">
        <div
          className="box-alert-close"
          onClick={closeFunction}
        >
          <Icon icon="close" />
        </div>
        <div className="box-alert-header">
          <div>
            <img src="./alert.svg" alt={title} height="52px" />
          </div>
          {title}
        </div>
        <p>
          {text}
        </p>
        <div className="box-alert-footer">
          {!!secondaryCtaButton && (
            <Button
              className="btn-ghost"
              onClick={closeFunction}
            >
              {secondaryCtaButton}
            </Button>
          )}
          <Button
            onClick={successFunction}
            hasRightIcon={!!btnIcon}
            icon={btnIcon}
          >
            {btnText}
          </Button>
          {!!secondaryCtaText && (
            <div
              className="unkown-link"
              onClick={closeFunction}
            >
              {secondaryCtaText}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

BoxModal.displayName = 'BoxModal';
export default BoxModal;
