import { compact, map, size } from 'lodash';
import type { FC } from 'react';
import React from 'react';

import type { AppConfigVos } from '../../../types';
import { formatCurrency } from '../../../utils/format';

import './../../../Cart.scss';

interface ICartAccessoriesProps {
  manufacturer:  { [p: string]: number } | undefined;
  config: AppConfigVos | null;
}

const CartMachines: FC<ICartAccessoriesProps> = ({
  manufacturer,
  config
}) => {
  if (!config?.machine_price) {
    return null;
  }

  const machineList = compact(map(manufacturer, (count, key) => {
    if (!count) {
      return undefined;
    }

    const total = count * parseFloat(String(config?.machine_price));

    return {
      key,
      count,
      price: config.machine_price,
      total,
      totalFormatted: formatCurrency(total)
    };
  }));
  const listToRender = [
    ...machineList
  ];

  return (
    <div>
      <div className="cart-row">
        <div className="cart-row-inner cart-row-inner-head">
          <div className="amount">Stk.</div>
          <div className="name">Hersteller</div>
          <div className="currency">Einzelpreis</div>
        </div>
      </div>
      <div className="cart-row">
        {size(listToRender) > 0 ? map(listToRender, accessory => (
          <div key={accessory.key} className="cart-row-inner">
            <div className="amount">
              {accessory.count} &times;
            </div>
            <div className="name">{accessory.key}</div>
            <div className="currency">{formatCurrency(accessory.price)}</div>
          </div>
        )) : (
          <div className="cart-row-inner">
            <em>Noch kein Hersteller ausgewählt.</em>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartMachines;
