export const headlineContentService = [
  {
    title: 'Einen WerkstattService beauftragen',
    lead: 'Mit der richtigen Auswahl beschleunigst du den Service, den du beauftragen möchtest.'
  },
  {
    title: 'Von Welchem Hersteller stammt ihr Gerät?',
    // title: 'Welches Gerät Wollen Sie Reparieren Lassen?',
    lead: 'Bitte wählen Sie aus, für welches Gerät Sie eine Inspektion beauftragen möchten.',
    // lead: 'Bitte wählen Sie aus, für welches Gerät Sie eine Inspektion beauftragen möchten.',
    back: 'Zurück zu WerkstattService'
  },
  {
    title: 'Service & Zubehör',
    lead: 'Bitte wählen Sie aus, für welches Gerät Sie eine Reparatur beauftragen möchten.',
    back: 'Zurück zu GeräteauswahL'
  },
  {
    title: 'Wählen Sie ihr zubehör',
    lead: 'Bitte wählen Sie aus, welches Zubehör Sie ihrem Service beilegen wollen.',
    back: 'Geräteauswahl'
  },
  {
    title: 'Adresse & Versand',
    lead: 'Bitte wählen Sie aus, für welches Gerät Sie eine Reparatur beauftragen möchten.',
    back: 'Geräteauswahl'
  },
  {
    title: 'Beauftragung Abgeschlossen',
    lead: 'Vielen Dank für Ihr Vertrauen in unseren Service.',
    back: 'Einen Service Beauftragen'
  }
];

export const MAX_STEPS_SERVICE = 6;
