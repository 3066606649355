import classNames from 'classnames';
import type { FC } from 'react';
import { useRef } from 'react';
import React, { useState, useContext } from 'react';

import Address from '../../../components/Address';
import AppointmentItem from '../../../components/AppointmentItem';
import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import { findClosestDates } from '../../../lib/filter';
import ConfigVosContext from '../../../service/context/config-vos';
import useAppStoreVos from '../../../service/store/appVos';
import useOrderStoreVos from '../../../service/store/orderVos';
import { validateOrderVos } from '../../../utils/validate';

import AppointmentFilter from './AppointmentFilter';
import './AppointmentList.scss';

interface IAppointmentListProps {
  placeReminder?: () => Promise<void>;
}

const AppointmentList: FC<IAppointmentListProps> = ({
  placeReminder
}) => {
  const errorRef = useRef<null | HTMLDivElement>(null);
  const [ showErrors, setShowErrors ] = useState(false);
  const [ tab, setTab ] = useState(0);
  const { filter, setFilter, setStep } = useAppStoreVos(state => state);
  const {
    order,
    setValue: setOrderValue,
    setAppointment: setOrderAppointment,
    setDealer: setOrderDealer
  } = useOrderStoreVos(state => state);
  const { date, search, city, customer } = filter;
  const appContext = useContext(ConfigVosContext);
  const { appointments, dealers, delivery_countries } = appContext || {};
  const filteredDealers = dealers?.filter(dealer => {
    if (
      search
      && (
        !dealer.customer.toLowerCase().includes(search.toLowerCase())
        && !dealer.address.toLowerCase().includes(search.toLowerCase())
      )
    ) {
      return false;
    }

    return true;
  });
  const filteredAppointments = appointments?.filter(appointment => {
    if (
      search
      && (
        !appointment.customer.toLowerCase().includes(search.toLowerCase())
        && !appointment.address.toLowerCase().includes(search.toLowerCase())
      )
    ) {
      return false;
    }

    if (city && appointment.city !== city) {
      return false;
    }

    if (customer && appointment.customer !== customer) {
      return false;
    }

    return true;
  });
  const filterByDate = date
    ? findClosestDates(filteredAppointments, date) : filteredAppointments;

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    setOrderValue(event.target.value, event.target.name);
  };

  const selectedDealer = order?.dealer;
  const validOrder = validateOrderVos(order, true);

  const handleCtaClick = (): void => {
    if (validOrder && placeReminder) {
      void placeReminder();
    } else {
      setShowErrors(true);
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      {!selectedDealer && (
        <>
          <div className="appointment-list-navi">
            <div className="tab-navi-wrap">
              <div className="tab-navi">
                <button
                  className={classNames('btn', {
                    inactive: tab !== 0
                  })}
                  onClick={() => setTab(0)}
                >
                  Anstehende Termine
                </button>
                <button
                  className={classNames('btn', {
                    inactive: tab !== 1
                  })}
                  onClick={() => setTab(1)}
                >
                  Alle Fachhändler
                </button>
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-item-serach">
                <Icon icon="search" />
                <input
                  onChange={event => setFilter('search', event.target.value)}
                  type="text"
                  value={search}
                  placeholder="Name/Adresse..."
                />
              </div>
            </div>
          </div>
          {tab === 0 && (
            <>
              <AppointmentFilter />
              <div className="appointment-list">
                {filterByDate && filterByDate.length > 0 ? (
                  <div className="appointment-item-list">
                    {filterByDate.map(appointment => (
                      <AppointmentItem
                        onClick={() => {
                          setOrderAppointment(appointment);
                          setOrderDealer(undefined);
                          setStep(2);
                        }}
                        key={appointment.row}
                        date={appointment.date}
                        month={appointment.month}
                        day={appointment.day}
                        title={appointment.customer}
                        address={appointment.address}
                      />
                    ))}
                  </div>
                ) : (
                  <div>
                    Keine Termine verfügbar
                  </div>
                )}
              </div>
            </>
          )}
          {tab === 1 && (
            <>
              <div className="appointment-list">
                {filteredDealers && filteredDealers.length > 0 ? (
                  <div className="appointment-item-list">
                    {filteredDealers.map(dealer => (
                      <AppointmentItem
                        onClick={() => {
                          setOrderDealer(dealer);
                          setOrderAppointment(undefined);
                        }}
                        key={dealer.customer}
                        title={dealer.customer}
                        address={dealer.address}
                      />
                    ))}
                  </div>
                ) : (
                  <div>
                    Keine Fachhändler verfügbar
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
      {selectedDealer && (
        <div className="box-overlay">
          <div className="box-overlay-head">
            <AppointmentItem
              title={selectedDealer.customer}
              address={selectedDealer.address}
              standalone
            />
            <div
              className="box-overlay-close"
              onClick={() => {
                setOrderDealer(undefined);
              }}
            >
              <Icon icon="close" />
            </div>
          </div>
          <div ref={errorRef}>
            {showErrors && !validOrder && (
              <div className="error-box">
                Bitte fülle aller Pflichtfelder aus.
              </div>
            )}
          </div>
          <Address
            showError={showErrors}
            propertyKey="address"
            address={order?.address}
            deliveryCountries={delivery_countries}
            onChange={handleInputChange}
            customerNumber={order?.customer_number}
            showCustomerNumber
            showCountrySelect
          />
          <div className="box-cta">
            <div className="box-cta-hint">
              {/* eslint-disable-next-line max-len */}
              Wir erinnern Sie wenn ein Vor-Ort-Service an diesem Standort stattfindet füllen Sie einfach das folgende
              Formular aus.
            </div>
            <Button
              onClick={handleCtaClick}
              hasRightIcon
              className={classNames({
                'btn-disabled': !validOrder
              })}
              icon="arrow-right"
              // disabled={!validOrder}
            >
              Jetzt erinnern lassen
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentList;
