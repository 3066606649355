import { find, map } from 'lodash';
import type { FC } from 'react';
import { useContext } from 'react';
import React from 'react';

import CartPortal from '../../../components/CartPortal';
import ContentNavigation from '../../../components/ContentNavigation';
import ProductAccessories from '../../../components/ProductAccessories';
import ProductJaws from '../../../components/ProductJaws';
import Button from '../../../components/ui/Button';
import Image from '../../../components/ui/Image';
import ConfigVosContext from '../../../service/context/config-vos';
import useAppStoreVos from '../../../service/store/appVos';
import useOrderStoreVos from '../../../service/store/orderVos';
import { UNKNOWN } from '../../../utils/constants';

import './../../../components/ManufacturerItem.scss';

const StepSelectAccessories: FC = () => {
  const appContext = useContext(ConfigVosContext);
  const { manufacturers_ext } = appContext || {};
  const { setStep } = useAppStoreVos(state => state);
  const {
    accessories,
    jaws,
    manufacturer
  } = useOrderStoreVos(state => state.order) || {};
  const setAccessories = useOrderStoreVos(state => state.setAccessories);
  const setJaws = useOrderStoreVos(state => state.setJaws);
  const config = useContext(ConfigVosContext);

  return (
    <>
      <ContentNavigation
        step={3}
        setStep={setStep}
        showBackButton
      >
        <Button
          className="btn-dark"
          onClick={() => setStep(4)}
          hasRightIcon
        >
          Weiter zu Adressdaten
        </Button>
      </ContentNavigation>
      <div className="box">
        <div className="box-content">
          {manufacturer && manufacturer.length > 0 && (
            <div className="container-row">
              <div className="input-container-title">Ihre Auswahl</div>
              <div className="manufacturer-selection">
                {map(manufacturer, (count, key) => {
                  const manufacturerItem = find(manufacturers_ext, item => item.name === key);

                  return (
                    <div>
                      <div>
                        {count} &times;
                      </div>
                      <div>
                        {manufacturerItem && manufacturerItem.key !== UNKNOWN ? (
                          <Image
                            url={`${manufacturerItem.key}.svg`}
                            alt={manufacturerItem.name}
                            type="manufacturer"
                          />
                        ) : (
                          <>
                            {key}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="form-grid">
            <div className="container-row">
              <div className="input-container-title">Zubehör</div>
              <ProductAccessories
                accessories={accessories}
                setAccessories={setAccessories}
                config={config}
              />
            </div>
          </div>
          <div className="form-grid">
            <div>
              <div className="input-container-title">Pressbacken</div>
              <ProductJaws
                jaws={jaws}
                setJaws={setJaws}
                config={config}
              />
            </div>
          </div>
          <div className="box-cta">
            <div className="box-cta-hint">
              <strong>Hinweis</strong>
              Sie können zu ihrem Auftrag Zubehör von anderen Herstellern beifügen und bearbeiten lassen.
            </div>
            <Button
              onClick={() => setStep(4)}
              hasRightIcon
              icon="arrow-right"
            >
              Weiter zu Adressdaten
            </Button>
          </div>
          <CartPortal>
            <Button
              onClick={() => setStep(4)}
              hasRightIcon
              icon="arrow-right"
            >
              Weiter zu Adressdaten
            </Button>
          </CartPortal>
        </div>
      </div>
    </>
  );
};

export default StepSelectAccessories;
