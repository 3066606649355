import type { FC } from 'react';
import React from 'react';

import Progressbar from '../../../components/ui/Progressbar';

interface IStepConfirmationProps {
  reset: () => void;
  resetButtonText?: string;
}

const StepConfirmation: FC<IStepConfirmationProps> = ({
  reset,
  resetButtonText
}) => {
  return (
    <div className="box">
      <div className="box-content">
        <Progressbar reset={reset} resetButtonText={resetButtonText} />
      </div>
    </div>
  );
};

export default StepConfirmation;
