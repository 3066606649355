import classNames from 'classnames';
import type { FC } from 'react';
import { useContext } from 'react';
import React, { useState, useEffect } from 'react';

import CartAccessories from '../../components/CartAccessories';
import ConfigVosContext from '../../service/context/config-vos';
import useAppStoreVos from '../../service/store/appVos';
import useOrderStoreVos from '../../service/store/orderVos';

import './../../components/ui/Button.scss';
import CartAppointment from './components/CartAppointment';
import CartMachines from './components/CartMachines';

const VosCart: FC = () => {
  const [ tab, setTab ] = useState(0);
  const {
    accessories, jaws, manufacturer
  } = useOrderStoreVos(state => state.order) || {};
  const config = useContext(ConfigVosContext);
  const { step } = useAppStoreVos(state => state);

  useEffect(() => {
    if (step === 1) {
      setTab(0);
    } else if (step === 2) {
      setTab(1);
    } else if (step === 3) {
      setTab(2);
    }
  }, [ step ]);

  return (
    <div>
      <div className="box has-no-shadow">
        <div className="box-content">
          <div className="tab-navi-wrap">
            <div className="tab-navi">
              <button
                className={classNames('btn', {
                  inactive: tab !== 0
                })}
                onClick={() => setTab(0)}
              >
                Fachhandel
              </button>
              <button
                className={classNames('btn', {
                  inactive: tab !== 1
                })}
                onClick={() => setTab(1)}
              >
                Maschine
              </button>
              <button
                className={classNames('btn', {
                  inactive: tab !== 2
                })}
                onClick={() => setTab(2)}
              >
                Zubehör
              </button>
            </div>
          </div>
          {tab === 0 && (<CartAppointment />)}
          {tab === 1 && (
            <CartMachines
              manufacturer={manufacturer}
              config={config}
            />
          )}
          {tab === 2 && (
            <CartAccessories
              accessories={accessories}
              jaws={jaws}
              config={config}
            />
          )}
        </div>
      </div>
      <div className="box-hint">
        <div>
          Alle Preise zzgl. Mehrwertsteuer
        </div>
        <div className="box-hint-logo">
          Powered by <img src="./toolservice-logo-light.png" alt="Tool Service Logo" height="20px" />
        </div>
      </div>
    </div>
  );
};

export default VosCart;
