import type { FC, ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface ICartPortalProps {
  children: ReactNode;
}

const CartPortal: FC<ICartPortalProps> = ({ children }) => {
  const cartRoot = document.getElementById('cart-portal');

  if (!cartRoot) {
    return null;
  }

  return ReactDOM.createPortal(
    children,
    cartRoot
  );
};

export default CartPortal;
